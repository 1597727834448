import React, { Fragment } from 'react';
import { FormattedTime, FormattedDate } from 'react-intl';
import './SelectTime.css';
import Button from '../../../components/Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
import BSLIcon from '../../../images/bsl_icon.png';
import ADIcon from '../../../images/ad_icon.png';
import WheelchairIcon from '../../../images/wheelchair_icon.png';

import classNames from 'classnames';

const SelectTime = ({
  performances,
  onSelectTime,
  performanceDateTime,
  performanceId,
  onDateClick,
  className
}) => {
  const handleDateClick = (e, performanceId) => {
    e.preventDefault();
    onDateClick && onDateClick(performanceId);
  };
  const handleSelectTime = (e, performanceId) => {
    e.preventDefault();
    onSelectTime && onSelectTime(performanceId);
  };
  const lozengeClass = classNames('date-time-bar__lozenge');
  const lozengeActionClass = classNames('date-time-bar__lozenge-action');
  return (
    <div className='select-time-container'>
      {performances.length > 0 && (
        <Fragment>
          <span className='select-time-container-header'>
            <div onClick={handleDateClick} className={lozengeClass}>
              <div className='date-time-bar__lozenge-selected'>
                <div className='date-time-bar__date'>
                  <FontAwesomeIcon
                    icon='calendar'
                    fixedWidth
                    className='icon'
                  />
                  <FormattedDate
                    value={performances[0].startDate}
                    year='numeric'
                    month='long'
                    day='2-digit'
                    weekday='long'
                  />
                </div>
              </div>
              <div className={lozengeActionClass}>Change</div>
            </div>
          </span>

          <span className='select-time-options'>
            {performances.map(performance => {
              const wheelchair =
                performance.startDate.format('HH:mm') === '12:00' ||
                (performance.startDate.format('HH:mm') === '18:00' &&
                  performance.id !== 6573812 &&
                  performance.id !== 6573848);

              const showAvailability = !!performance.availability;
              const soldOut = performance.availability === 0;
              const availabilityWidth = showAvailability
                ? Math.max(
                    parseInt((1 - performance.availability) * 100, 10),
                    50
                  )
                : 0;
              return (
                <Button
                  onClick={e => handleSelectTime(e, performance.id)}
                  key={performance.id}
                  className='time-button'
                >
                  <div className='time-button-availability__indicator'>
                    <span className='time-button-availability__label'>
                      <FormattedTime value={performance.startDate.toDate()} />
                      {soldOut ? <span> - Sold Out</span> : null}
                    </span>
                  </div>

                  {wheelchair && (
                    <Fragment>
                      <Tooltip
                        title='This performance is accessible for customers who require a wheelchair. To book an access ticket please call the box office on 0203 053 1500'
                        position='bottom'
                        distance='-2'
                        trigger='mouseenter'
                      >
                        <div className='select-time__specific-requirements--wheelchair'>
                          <img
                            src={WheelchairIcon}
                            className='select-time__specific-requirements-icon'
                            alt='Wheelchair'
                          />
                        </div>
                      </Tooltip>
                    </Fragment>
                  )}

                  {performance.id === 6573812 && (
                    <Fragment>
                      <Tooltip
                        title='This performance will incorporate BSL interpreters for those who require them'
                        position='bottom'
                        distance='-2'
                        trigger='mouseenter'
                      >
                        <div className='select-time__specific-requirements'>
                          <img
                            src={BSLIcon}
                            className='select-time__specific-requirements-icon'
                            alt='BSL Performance'
                          />
                        </div>
                      </Tooltip>
                    </Fragment>
                  )}

                  {performance.id === 6573848 && (
                    <Fragment>
                      <Tooltip
                        title='This performance has audio description for those that require it'
                        position='bottom'
                        distance='-2'
                        trigger='mouseenter'
                      >
                        <div className='select-time__specific-requirements'>
                          <img
                            src={ADIcon}
                            className='select-time__specific-requirements-icon'
                            alt='AD Performance'
                          />
                        </div>
                      </Tooltip>
                    </Fragment>
                  )}

                  {soldOut ? (
                    <div className='time-button-availability__indicator-bar time-button-availability__indicator-bar--sold-out' />
                  ) : (
                    <div
                      className='time-button-availability__indicator-bar'
                      style={{
                        width: `${availabilityWidth}%`
                      }}
                    />
                  )}
                </Button>
              );
            })}
          </span>
        </Fragment>
      )}
    </div>
  );
};

export default SelectTime;
