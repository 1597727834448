import React, { Fragment } from 'react';
/* eslint-disable react/style-prop-object */
import { FormattedNumber, FormattedPlural } from 'react-intl';
import Button from '../../Button/Button';
import classNames from 'classnames';

import './BasketTotal.css';

const BasketTotal = ({
  basketTotal,
  numberOfTickets,
  bookingFeeTotal,
  currency,
  onBookNowClick,
  isCreatingReservation,
  footerButtonColor,
  wolfBooking,
  className,
  buttonText = 'Confirm & Book'
}) => {
  const styles = {
    backgroundColor: `${footerButtonColor}`
  };
  const handleBookNowClick = e => {
    e.preventDefault();
    onBookNowClick && onBookNowClick();
  };
  const basketCTAButtonClass = classNames(
    'button button--mobile-full button--bottom-bar',
    className,
    {
      'button--bottom-bar-wolf': wolfBooking
    }
  );

  return (
    <Fragment>
      {basketTotal > 0 && (
        <Fragment>
          <span className="basket-total-bar-total">
            <span className="basket-total-bar-total-item basket-total-bar-total-item--price">
              <span className="basket-total-bar-total__total">Total: </span>
              <FormattedNumber
                value={basketTotal}
                style="currency"
                currency={currency}
              />
            </span>
            {bookingFeeTotal > 0 && (
              <span className="basket-total-bar-total-item basket-total-bar-total-item--fee">
                (incl. fees)
              </span>
            )}
            <span className="basket-total-bar-total-item basket-total-bar-total-item--tickets">
              {`: ${numberOfTickets} `}
              <FormattedPlural
                value={numberOfTickets}
                one="Item"
                other="Items"
              />
            </span>
          </span>
          <span className="basket-total-cta">
            <Button
              className={basketCTAButtonClass}
              onClick={handleBookNowClick}
              loading={isCreatingReservation}
              style={styles}
            >
              {buttonText}
            </Button>
          </span>
        </Fragment>
      )}
    </Fragment>
  );
};

export default BasketTotal;
