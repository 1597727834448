import React from 'react';
import Button from '../../../Button/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
/* eslint-disable react/style-prop-object */
import { FormattedNumber } from 'react-intl';
import classNames from 'classnames';
import './TicketRow.css';

const TicketRow = ({
  ticketId,
  name,
  detail,
  faceValue,
  bookingFee,
  currency,
  quantityAvailable,
  quantitySelected,
  onAddToBasketClick,
  onRemoveFromBasketClick,
  minimumQuantity = 1,
  className,
  wolfBooking,
  disabled
}) => {
  const quantityLeft = quantityAvailable - quantitySelected;
  const soldOut = quantityAvailable <= 0;
  const lowInventoryLevel = 4;

  const handleAddToBasketClick = evt => {
    evt.preventDefault();
    if (!soldOut && !disabled && quantityLeft >= minimumQuantity) {
      const quantity = Math.max(minimumQuantity - quantitySelected, 1);
      onAddToBasketClick(ticketId, quantity);
    }
  };

  const handleRemoveFromBasketClick = evt => {
    evt.preventDefault();
    evt.stopPropagation();
    if (quantitySelected > 0) {
      const quantity =
        quantitySelected === minimumQuantity ? minimumQuantity : 1;
      onRemoveFromBasketClick(ticketId, quantity);
    }
  };

  const rowGroupClass = classNames('ticket-table-row-group', className, {
    'ticket-table-row-group--in-basket': quantitySelected > 0,
    'ticket-table-row-group--sold-out': soldOut || disabled,
    'ticket-table-row-group--wolf': wolfBooking,
    'ticket-table-row-group--wolf-in-basket':
      wolfBooking && quantitySelected > 0
  });

  const ticketControlClassPlus = classNames(
    className,
    'ticket-table-row-item-ticket-control-button',
    {
      'ticket-table-row-item-ticket-control-button--disabled':
        quantityLeft === 0,
      'ticket-table-row-item-ticket-control-button--wolf': wolfBooking
    }
  );

  const ticketControlClassMinus = classNames(
    className,
    'ticket-table-row-item-ticket-control-button'
  );

  return (
    <span className={rowGroupClass} onClick={handleAddToBasketClick}>
      <span className='ticket-table-row'>
        <span className='ticket-table-row-item ticket-table-row-item--ticket-info'>
          <span className='ticket-table-row-item-ticket-name'>{name}</span>
          {detail && (
            <span className='ticket-table-row-item-ticket-description'>
              {detail}
            </span>
          )}
        </span>
        <span className='ticket-table-row-item ticket-table-row-item--ticket-price'>
          {faceValue && (
            <span className='ticket-table-row-item--ticket-price-face'>
              <FormattedNumber
                value={faceValue}
                style='currency'
                currency={currency}
              />
            </span>
          )}
          {bookingFee > 0 && (
            <span className='ticket-table-row-item--ticket-price-fee'>
              (incl. fees)
            </span>
          )}
        </span>
        {soldOut || disabled ? (
          <span className='ticket-table-row-item ticket-table-row-item--ticket-controls ticket-table-row-item--sold-out'>
            <span className='sold-out-label'>
              {soldOut ? 'Sold Out' : 'Coming Soon'}
            </span>
          </span>
        ) : (
          <span className='ticket-table-row-item ticket-table-row-item--ticket-controls'>
            <span className='ticket-table-row-item-ticket-buttons'>
              <div className='ticket-table-row-item-ticket-control ticket-table-row-item-ticket-control--minus'>
                {quantitySelected > 0 && (
                  <Button
                    className={ticketControlClassMinus}
                    onClick={handleRemoveFromBasketClick}
                  >
                    <FontAwesomeIcon
                      icon='minus'
                      fixedWidth
                      className='ticket-control-icon'
                    />
                  </Button>
                )}
              </div>
              <span className='ticket-table-row-item-ticket-control-tally'>
                {quantitySelected}
              </span>
              <div className='ticket-table-row-item-ticket-control ticket-table-row-item-ticket-control--plus'>
                <Button
                  disabled={quantityLeft === 0 ? 'disabled' : ''}
                  className={ticketControlClassPlus}
                >
                  <FontAwesomeIcon
                    icon='plus'
                    fixedWidth
                    className='ticket-control-icon'
                  />
                </Button>
              </div>
            </span>
            {quantityAvailable <= lowInventoryLevel && (
              <span className='ticket-table-row-item-ticket-remaining'>
                {quantityLeft} remaining
              </span>
            )}
          </span>
        )}
      </span>
    </span>
  );
};

export default TicketRow;
