import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';

import {
  Calendar,
  BookingPage,
  FooterMenu
} from '../../../components/Booking/Booking';
import FooterBar from '../../../components/FooterBar/FooterBar';
import {
  performanceActions,
  performanceSelectors
} from '../../../modules/performances/performances';

class CalendarContainer extends Component {
  componentDidMount() {
    this.props.fetchPerformances(this.props.eventSlug);
  }
  handlePerformanceClick = performanceId => {
    this.props.history.push(`${this.props.match.url}/${performanceId}/tickets`);
  };
  handleDayClick = day => {
    const dayString = moment(day).format('YYYY-MM-DD');
    this.props.history.push(`${this.props.match.url}/${dayString}/select-time`);
  };
  render() {
    return (
      <Fragment>
        <BookingPage
          title={this.props.calendarTitle}
          isLoading={this.props.performancesLoading}
          gatsbyBooking={this.props.gatsbyBooking}
          wolfBooking={this.props.wolfBooking}
        >
          <Calendar
            performancesByDay={this.props.performancesByDay}
            onPerformanceClick={this.handlePerformanceClick}
            onDayClick={this.handleDayClick}
            wolfBooking={this.props.wolfBooking}
          />
        </BookingPage>
        <FooterBar
          footerBackgroundColor={this.props.footerBackgroundColor}
          footerBorderColor={this.props.footerBorderColor}
          footerLinkColor={this.props.footerLinkColor}
          footerFontFamily={this.props.footerFontFamily}
          footerButtonColor={this.props.footerButtonColor}
        >
          <FooterMenu
            showBook={true}
            lng={this.props.lng}
            gatsbyButton={this.props.gatsbyButton}
            wolfButton={this.props.wolfButton}
            footerButtonColor={this.props.footerButtonColor}
            links={this.props.footerLinks}
            wolfBooking={this.props.wolfBooking}
          />
        </FooterBar>
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const { eventSlug, lng } = match.params;
  return {
    eventSlug,
    lng,
    performancesByDay: performanceSelectors.getPerformancesGroupedByDay(state),
    performancesLoading: performanceSelectors.getLoading(state)
  };
};

export default connect(mapStateToProps, {
  ...performanceActions
})(CalendarContainer);
