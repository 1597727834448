import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Button from '../../../../Button/Button';
import FormField from '../../../../Form/FormField/FormField';
import './DiscountForm.css';
import classNames from 'classnames';

const validate = values => {
  const errors = {};
  if (!values.code) {
    errors.code = 'Please enter a Discount Code';
  }
  return errors;
};

let DiscountForm = ({
  error,
  handleSubmit,
  pristine,
  submitting,
  className,
  wolfBooking
}) => {
  const applyDiscountButtonClass = classNames(
    'button--booking discount-button',
    className,
    {
      'discount-button--wolf': wolfBooking
    }
  );
  return (
    <form onSubmit={handleSubmit} className="discount-form">
      <Field
        name="code"
        component={FormField}
        type="text"
        placeholder="Discount or Voucher code"
        label="Voucher / Promo code"
        hideLabel={true}
        className="discount-field"
      />
      <Button
        className={applyDiscountButtonClass}
        type="submit"
        disabled={pristine || submitting}
        loading={submitting}
      >
        Apply
      </Button>
    </form>
  );
};

DiscountForm = reduxForm({
  form: 'discount',
  validate
})(DiscountForm);

export default DiscountForm;
