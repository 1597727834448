export const getAccordionPreExpanded = anchorId => {
  const { hash } = window.location;
  const id = hash.replace('#', '');
  let preExpanded = [];
  if (id === anchorId) {
    preExpanded = [anchorId];
  }

  return preExpanded;
};
