import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import ImmersiveAccordion from './ImmersiveAccordion';
import './Accordion.css';
import { getAccordionPreExpanded } from './getAccordionPreExpanded';

const ImmersiveAccordionHeader = () => {
  const preExpanded = getAccordionPreExpanded('immersive');

  return (
    <div id='immersive'>
      <Accordion
        className='accordion'
        allowZeroExpanded
        preExpanded={preExpanded}
      >
        <AccordionItem uuid='immersive'>
          <AccordionItemHeading>
            <AccordionItemButton>
              <h3 className='gatsby-h3'>Immersive Theatre</h3>
              <div className='accordion__arrow' />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <ImmersiveAccordion />
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default ImmersiveAccordionHeader;
