import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import CovidAccordion from './CovidAccordion';
import './Accordion.css';
import { getAccordionPreExpanded } from './getAccordionPreExpanded';

const CovidAccordionHeader = () => {
  const preExpanded = getAccordionPreExpanded('covid');

  return (
    <div id='covid'>
      <Accordion
        className='accordion'
        allowZeroExpanded
        preExpanded={preExpanded}
      >
        <AccordionItem uuid='covid'>
          <AccordionItemHeading>
            <AccordionItemButton>
              <h3 className='gatsby-h3'>Covid-19</h3>
              <div className='accordion__arrow' />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <CovidAccordion />
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default CovidAccordionHeader;
