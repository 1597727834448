import React from 'react';
import Button from '../../components/Button/Button';
import DWTFFooter from '../../components/DWTFFooter/DWTFFooter';
import MainContent, {
  ContentContainer,
  PageContentBlock,
  TextBlock
} from '../../components/MainContent/MainContent';
import DWTFLogoLarge from '../../images/doctor-who/DoctorWho_logo_large.png';

import SpecificRequirementsPDFDownload from '../../images/DWTF-Specific-Requirements-Downloadable.pdf';
import SpecificRequirementsPDFLargeDownload from '../../images/DWTF-Specific-Requirements-Large-Font-Downloadable.pdf';
import SpecificRequirementsWordDownload from '../../images/DWTF-Specific-Requirements-Downloadable.docx';
import ADIntro from '../../images/DWTF AD Intro Transcript.docx';

const DoctorWhoUpdate = ({ match }) => {
  return (
    <div className='App App--gatsby App--gatsby-terms'>
      <MainContent gatsby>
        <ContentContainer shadow='true' gatsby='true'>
          <PageContentBlock>
            <a className='terms-logo' href='/'>
              <img
                src={DWTFLogoLarge}
                alt='Doctor Who: Time Fracture'
                className='full-image-background-cover__content-image'
              />
            </a>
            {/* <h2 className='gatsby-h2'>Update</h2> */}

            <TextBlock toppadding textleft full>
              <h2 className='gatsby-h2'>Specific Requirements</h2>

              <p>
                The Immersive Everywhere and Arts Tickets team can assist you
                with your booking for Doctor Who: Time Fracture and give you
                more information about the show, facilities at the show venues
                and ticket prices available. If you cannot find the information
                you require on the website, please let us know.
              </p>
              <p>
                Access tickets will need to be purchased over the phone. For
                wheelchair accessibility, tickets will need to be booked for the
                12pm or 6pm performances.
              </p>
              <p>
                You can contact Arts Tickets to book or discuss your
                requirements in a range of ways:
              </p>
              <p>
                Telephone: 0203 053 1500
                <br />
                E-Mail:{' '}
                <a href='mailto:ticketing@artstickets.co.uk'>
                  ticketing@artstickets.co.uk
                </a>
                <br />
                SMS: 07984 414 175
              </p>
              <p>Opening Hours: Monday – Friday, 10am – 6pm</p>

              <div className='specific-requirements-section'>
                <h3 className='gatsby-h3'>
                  Doctor Who: Time Fracture – The Show
                </h3>
                <p>
                  Doctor Who: Time Fracture is an immersive theatre experience –
                  this means that, rather than sitting in a seat and watching
                  actors on a stage as in a traditional theatre, the actors and
                  audience are all on the same level and the audience can get
                  involved with the performance if they wish.
                </p>
                <p>
                  There are a number of characters in the show and there will be
                  opportunities to follow certain characters into other rooms or
                  areas. This means that your experience of the show may turn
                  out differently to some of the other audience members as you
                  split into smaller groups. You will not be required to split
                  up from your friends or family at any point – you need only
                  get as involved as you are comfortable with.
                </p>
              </div>

              <div className='specific-requirements-section'>
                <h3 className='gatsby-h3'>What To Tell Us</h3>
                <p>
                  So that the venue staff can ensure you have an enjoyable
                  experience, you can let us know when you book your tickets
                  whether you have any specific requirements, such as the need
                  for step free access or if you will be bringing an assistance
                  dog. You can discuss your needs by phone or SMS in confidence
                  with our staff or you can add your requirements to your order
                  when you book online.
                </p>
                <p>
                  There will be a BSL Interpreted performance on Thursday 17th
                  March 2022 at 6:00pm.{' '}
                  <a
                    className='faq_link'
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://amex.immersivedoctorwho.com/event/doctor-who-time-fracture-amex/6573812/tickets'
                  >
                    Tickets can be booked here
                  </a>
                  .
                </p>
                <p>
                  There will be an Audio Described performance on Thursday 31st
                  March 2022 at 6pm.{' '}
                  <a
                    className='faq_link'
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://amex.immersivedoctorwho.com/event/doctor-who-time-fracture-amex/6573848/tickets'
                  >
                    Tickets can be booked here
                  </a>
                  .
                </p>
                <p>
                  For more information on Audio Described or BSL Interpreted
                  performances, you can let us know by phone, email or SMS. Our
                  team can let you know when these have been scheduled. They may
                  also be highlighted for you on the booking calendar so keep an
                  eye out for the accessibility icons.
                </p>
                <p>
                  Please see below for information regarding light and sound
                  sensitivities.
                </p>
              </div>

              <div className='specific-requirements-section'>
                <h3 className='gatsby-h3'>Content Warnings</h3>
                <p>
                  On entering the experience, patrons are given a role and
                  objectives in the experience. Patrons will have the
                  opportunity to engage verbally with performers by invitation
                  during the experience. Patrons are expected to behave
                  respectfully towards performers and staff members, as
                  performers and staff members have a duty of care to prioritise
                  the safety of patrons.
                </p>
                <p>
                  The following elements of the production have been identified
                  as potentially requiring specific supports in terms of safety
                  and safeguarding:
                  <ul>
                    <li>
                      Angels Sequence: there is a portion of the show where
                      patrons encounter the Angels figures in a small space. The
                      production understands the scare-factor of these figures,
                      and have facilitated for patrons to identify to the ground
                      staff if they wish to bypass this interaction.
                    </li>
                    <li>
                      Battle Sequences: there will be physical fight sequences
                      between actors, sometimes using prop weapons.
                    </li>
                    <li>
                      Bars: consumption of alcohol is available during the
                      experience. Patrons are expected to drink responsibly and
                      make use of non-alcoholic options. Intoxication-related
                      disruptions will not be tolerated and may result in
                      ejection.
                    </li>
                    <li>
                      Light Effects: the experience makes use of strobe lights.
                      If you have any lighting sensitivities that could impact,
                      please contact ticketing@artstickets.co.uk prior to
                      booking to determine the possibility of navigating the
                      experience safely.
                    </li>
                    <li>
                      Sound Effects: the experience makes use of loud sound,
                      alarms, gunfire and explosion sound effects. Auditory
                      sensitivities should be flagged with ground staff before
                      the show to determine whether there are appropriate places
                      to be located during these uses. If you have any sound
                      sensitivities that could impact your health, please
                      contact ticketing@artstickets.co.uk prior to booking to
                      determine the possibility of navigating the experience
                      safely.
                    </li>
                  </ul>
                </p>
              </div>

              <div className='specific-requirements-section'>
                <h3 className='gatsby-h3'>Essential Companion Concessions</h3>
                <p>
                  For customers who require an essential companion to accompany
                  them to Doctor Who: Time Fracture, we offer one free of charge
                  ticket for every standard ticket bought. If you are eligible
                  for this free ticket, please contact Arts Tickets using the
                  details above to make a booking.
                </p>
                <p>
                  If you purchased a Gallifreyan Coin token, you would need to
                  book your ticket and then contact us through your preferred
                  method to arrange your Essential Companion ticket.
                </p>
                <p>
                  We do our best to protect this concession for those who rely
                  on it and ensure any free assistance tickets are being issued
                  only to those who need them. We reserve the right to refuse
                  entry and cancel tickets where we reasonably believe this
                  concession has been abused.
                </p>
                <p>
                  If you have requirements, we have not covered in our online
                  options, but you feel should be included, please do let us
                  know so we can rectify this for you.
                </p>
              </div>

              <div className='specific-requirements-section'>
                <h3 className='gatsby-h3'>Venue Accessibility Information</h3>
                <p>
                  We want to ensure that you feel comfortable and well-informed
                  for your visit to Doctor Who: Time Fracture. We can provide
                  the following information in a range of formats by email or
                  over the phone.
                </p>
                <h4 className='gatsby-h4'>Assistance Animals</h4>
                <p>
                  Working/Guide dogs or other assistance animals are welcome -
                  they can be taken into the show. Unfortunately, we are not
                  able to provide care for you animal during the performance.
                </p>
                <h4 className='gatsby-h4'>
                  Accessibility for Wheelchair Users
                </h4>
                <p>
                  Although Doctor Who: Time Fracture runs multiple narratives at
                  the same time, meaning it is impossible for any one audience
                  member to see all scenes in the show during a single visit,
                  there is a level access track available to guests for the
                  entire performance for one time slot which is clearly listed
                  on the website. Wheelchair users will experience a unique
                  route through the experience. A designated actor and member of
                  Front of House will be on hand to provide support and
                  narrative.
                </p>
                <p>
                  Our lift runs alongside the edge of the stairs from the first
                  floor to basement. It has a maximum weight limit of 300KG
                  (approx 660 pounds, 47 stone). Please note that your companion
                  will be able to walk up or down the stairs alongside the lift
                  being operated. Please advise staff of any concerns about
                  exceeding this limit before purchasing your ticket.
                </p>
                <h4 className='gatsby-h4'>Accessible Toilets</h4>
                <p>
                  There is an accessible bathroom on the ground floor of the
                  venue. The door width measures 910mm and there are grab
                  handles and safety alarms for your convenience. The bathroom
                  is highlighted via signs and any member of the front of house
                  team can guide you to the accessible bathroom through the
                  performance spaces upon request. There is a second accessible
                  bathroom on the basement level, near the interval bar.
                </p>
                <p>
                  There are additionally two sets of existing cubicle toilets.
                  The first set is on the ground floor. The cubicles are
                  approximately 690mm wide and do not have handrails or alarms
                  installed. The second are on the basement level - the cubicles
                  are approximately 580mm wide and do not have handrails or
                  alarms installed
                </p>
                <h4 className='gatsby-h4'>
                  Step-Free Doctor Who: Time Fracture
                </h4>
                <p>
                  The show is performed across two levels; the upper level is
                  accessed via a set of stairs. Due to the nature of the
                  performance, if you are not able or do not wish to climb the
                  stairs, this will not have an effect on the overall experience
                  as there will be other ‘strands’ of action available to follow
                  on the ground floor or via the lift.
                </p>
                <h4 className='gatsby-h4'>Doors</h4>
                <p>
                  Front Door: 1370mm wide, Single door, Heavy, kept open during
                  entry and exit periods to ease the flow of customers moving in
                  and out of the building.
                </p>
                <p>Downstairs Bathroom: 730mm, Push Door, Light</p>
                <p>Ground Floor Bathroom: 890mm wide, Push Door, Light</p>
                <p>
                  Time Fracture Entrance: 1200mm low lighting with special
                  effect corridors
                </p>
                <p>All on set doors are 800mm</p>
              </div>
              <div className='specific-requirements-section'>
                <h4 className='gatsby-h4'>Hearing Loop</h4>
                <p>
                  As the production crosses many different sets and performance
                  spaces and our patrons pass through each of them at their own
                  pace, the current affordable hearing loop technology does not
                  provide a sufficient solution.
                </p>
                <p>
                  Due to the style of the performance, it may be possible to
                  position yourself closer to the cast members in order to hear
                  or lip-read. If this is something you wish to do, please make
                  yourself known to staff on your arrival.
                </p>
                <p>
                  There will be a BSL Interpreted performance on Thursday 17th
                  March 2022 at 6:00pm.{' '}
                  <a
                    className='faq_link'
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://amex.immersivedoctorwho.com/event/doctor-who-time-fracture-amex/6573812/tickets'
                  >
                    Tickets can be booked here
                  </a>
                  .
                </p>
                <p>
                  There will be an Audio Described performance on Thursday 31st
                  March 2022 at 6pm.{' '}
                  <a
                    className='faq_link'
                    target='_blank'
                    rel='noopener noreferrer'
                    href='https://amex.immersivedoctorwho.com/event/doctor-who-time-fracture-amex/6573848/tickets'
                  >
                    Tickets can be booked here
                  </a>
                  .
                </p>
                <h4 className='gatsby-h4'>Travel Information</h4>
                <p>
                  You can view full travel information by{' '}
                  <a
                    href='https://tfl.gov.uk/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    clicking here
                  </a>
                  .
                </p>
                <p>
                  <b>Public Transport</b>
                </p>
                <p>
                  Train: The closest main line station is Paddington, which also
                  provides access to the Northern and Jubilee lines. Google Maps
                  states that Paddington station is a 28 minute walk from the
                  venue.
                </p>
                <p>
                  Tube: The nearest Tube station is Bond Street, which is
                  serviced by the Jubilee and Circle lines. Google Maps states
                  that Bond Street station is a 2 minute walk away. The route is
                  serviced by dropped kerbs.
                </p>
                <p>Tube Maps:</p>
                <p>
                  Click on the map you'd like to view to open it in a new
                  window:
                </p>
                <ul>
                  <li>
                    <a
                      href='http://content.tfl.gov.uk/large-print-tube-map.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Large Print Tube Map
                    </a>
                  </li>
                  <li>
                    <a
                      href='http://content.tfl.gov.uk/avoiding-stairs-tube-guide.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Avoiding Stairs Tube Guide
                    </a>
                  </li>
                  <li>
                    <a
                      href='http://content.tfl.gov.uk/step-free-tube-guide-map.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Step Free Tube Guide
                    </a>
                  </li>
                  <li>
                    <a
                      href='http://content.tfl.gov.uk/tube-map-with-tunnels.pdf'
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      Claustrophobia / Anxiety Tube Map
                    </a>
                  </li>
                </ul>
                <p>
                  Bus: Oxford Street is serviced by the 390, 24 bus (Victoria –
                  Oxford Circus; see full route by{' '}
                  <a
                    href='https://tfl.gov.uk/bus/route/c10/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    clicking here
                  </a>
                  ).
                </p>
                <p>
                  <b>Driving</b>
                </p>
                <p>
                  Disabled Parking Bays: Local Car Parks: NCP Car Park London
                  Grosvenor Hill – 2 disabled bays, 200 spaces, 5 minute walk
                  from UNIT HQ (via Google Maps). Costs from £14.00 per hour
                  (cash not accepted), cheaper if booked in advance online:{' '}
                  <a
                    href='https://www.ncp.co.uk/find-a-car-park/car-parks/london-grosvenor-hill/'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    NCP Grosvenor Hill Website
                  </a>
                </p>

                <p>
                  <b>Cycling </b>
                </p>
                <p>
                  There are free to use cycle parking facilities in the venue,
                  subject to availability. These can be accepted at the entrance
                  to the venue. There are Santander cycle docking stations on
                  Woodstock Street (a 2 minute walk from the venue via Google
                  Maps) and on Marylebone Lane (a 2 minute walk from the venue
                  via Google Maps).
                </p>
              </div>

              <div className='specific-requirements-section'>
                <h4 className='gatsby-h4'>Formats & Downloads</h4>
                <p>
                  To{' '}
                  <a
                    href={SpecificRequirementsPDFDownload}
                    className='faq_link'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    download this information as a .PDF to read in your own
                    time, click here.
                  </a>
                </p>
                <p>
                  To download this information in larger print,{' '}
                  <a
                    href={SpecificRequirementsWordDownload}
                    className='faq_link'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    click here for an adjustable Word document
                  </a>{' '}
                  or{' '}
                  <a
                    href={SpecificRequirementsPDFLargeDownload}
                    className='faq_link'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    here for a .PDF in font size 20.
                  </a>
                </p>
                <p>
                  <b>Audio Downloads</b>
                </p>
                <p>
                  <a
                    href='https://drive.google.com/file/d/1hHq07wp3iU9uynsrWisnGqg48QUOpzMR/view'
                    className='faq_link'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    .mp3 AD Download
                  </a>
                </p>
                <p>
                  <a
                    href='https://drive.google.com/file/d/1sdRIZ4p3owFG5sRL3_jnMYHkfx9JW-dv/view'
                    className='faq_link'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    .wav AD Download
                  </a>
                </p>
                <p>
                  AD sound file transcript -{' '}
                  <a
                    href={ADIntro}
                    className='faq_link'
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    download .docx file
                  </a>
                </p>
                <p>
                  We can provide this information in a range of formats – please
                  feel free to ask!
                </p>

                <p>Telephone: 0203 053 1500</p>

                <p>
                  E-Mail:{' '}
                  <a href='mailto:ticketing@artstickets.co.uk'>
                    ticketing@artstickets.co.uk
                  </a>
                </p>

                <p>SMS: 07984 414 175</p>
              </div>
            </TextBlock>
            <TextBlock toppadding>
              <div className='large-cta terms-cta'>
                <a href='/'>
                  <Button className='button--gatsby button--mobile-full'>
                    Back to Booking
                  </Button>
                </a>
              </div>
              <p className='credits'>
                BBC, DOCTOR WHO, TARDIS, DALEKS and CYBERMAN (word marks and
                logos) are trade marks of the British Broadcasting Corporation
                and are used under licence. BBC logo © BBC 1996. Doctor Who logo
                © BBC 2018. Dalek image © BBC/Terry Nation 1963. Cyberman image
                © BBC/Kit Pedler/Gerry Davis 1966. Licensed by BBC Studios.
              </p>
            </TextBlock>
          </PageContentBlock>
        </ContentContainer>
      </MainContent>

      <DWTFFooter />
    </div>
  );
};

export default DoctorWhoUpdate;
