import React from 'react';
import Button from '../Button/Button';
import FooterBar from '../FooterBar/FooterBar';
import './Form.css';
import classNames from 'classnames';

const Form = ({
  handleSubmit,
  submitText = 'Submit',
  formTitle,
  guidanceNotes,
  children,
  submitting = false,
  pristine,
  error,
  className,
  wolfBooking,
  useFooterButton = true
}) => {
  const footerButtonClass = classNames(
    'button button--event button--mobile-full',
    className,
    {
      'button--bottom-bar-wolf': wolfBooking
    }
  );
  const formButtonClass = classNames('button button--mobile-full', className, {
    'button--wolf': wolfBooking
  });
  return (
    <div className="form-container">
      <form className="form" onSubmit={handleSubmit}>
        <div className="form__header">
          <h5 className="form__header-title">{formTitle}</h5>
          {guidanceNotes && !error && (
            <span className="form__header-notes">{guidanceNotes}</span>
          )}
          {error && <span className="form__form-error">{error}</span>}
        </div>
        <div className="form__form-area">{children}</div>
        {useFooterButton ? (
          <div className="form__footer">
            <FooterBar>
              <div className="form__submit">
                <Button
                  className={footerButtonClass}
                  disabled={pristine || submitting}
                  loading={submitting}
                  onClick={handleSubmit}
                  type="submit"
                >
                  {submitText}
                </Button>
              </div>
            </FooterBar>
          </div>
        ) : (
          <div className="form__footer">
            <div className="form__submit">
              <Button
                className={formButtonClass}
                disabled={pristine || submitting}
                loading={submitting}
                onClick={handleSubmit}
                type="submit"
              >
                {submitText}
              </Button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default Form;
