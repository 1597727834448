import React from 'react';
import Spinner from 'react-spinkit';
import './LoadingIndicator.css';

const LoadingIndicator = ({ loadingMessage }) => {
  return (
    <div className='loading-indicator'>
      <Spinner name='ball-grid-pulse' fadeIn='none' color='#ff9e1b' />
      {loadingMessage && <p>{loadingMessage}</p>}
    </div>
  );
};

export default LoadingIndicator;
