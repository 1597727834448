import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { BookingPage, ProductTable } from '../../../components/Booking/Booking';
import FooterBar from '../../../components/FooterBar/FooterBar';
import { Redirect } from 'react-router-dom';
import ProductRowContainer from './ProductRowContainer';

import {
  productActions,
  productSelectors
} from '../../../modules/products/products';

import { basketActions, basketSelectors } from '../../../modules/basket/basket';
import {
  reservationActions,
  reservationSelectors
} from '../../../modules/reservation/reservation';

import BasketTotalContainer from '../Footer/BasketTotalContainer';

class ProductTableContainer extends Component {
  handleAddProductToBasketClick = productId => {
    this.props.addProductToBasket(productId);
  };
  handleRemoveProductFromBasketClick = productId => {
    this.props.removeProductFromBasket(productId);
  };
  handleBookNowClick = () => {
    const { sessionId, basket, createReservation, eventSlug, lng } = this.props;
    const data = {
      ...(sessionId && { sessionId: sessionId }),
      ...basket
    };
    createReservation({
      body: data,
      successUrl: `/${lng ? lng + '/' : ''}event/${eventSlug}/confirm`
    });
  };

  render() {
    const { eventSlug, lng, products } = this.props;
    if (products.length === 0) {
      return (
        <Redirect to={`/${lng ? lng + '/' : ''}event/${eventSlug}/confirm`} />
      );
    }
    return (
      <Fragment>
        <BookingPage
          isLoading={this.props.productsLoading}
          title='Select optional products'
          gatsbyBooking={this.props.gatsbyBooking}
          wolfBooking={this.props.wolfBooking}
        >
          <ProductTable
            products={this.props.products}
            wolfBooking={this.props.wolfBooking}
            renderProductRow={product => (
              <ProductRowContainer
                key={product.id}
                name={product.title}
                description={product.description}
                total={product.total}
                currency={product.currency}
                image={product.image}
                productId={product.id}
                quantityAvailable={product.inventoryLeft || 100}
                onAddToBasketClick={this.handleAddProductToBasketClick}
                onRemoveFromBasketClick={
                  this.handleRemoveProductFromBasketClick
                }
                wolfBooking={this.props.wolfBooking}
              />
            )}
          />
        </BookingPage>
        {this.props.numberOfItems > 0 && (
          <FooterBar
            footerFontFamily={this.props.footerFontFamily}
            footerButtonColor={this.props.footerButtonColor}
            footerBackgroundColor={this.props.footerBackgroundColor}
            wolfBooking={this.props.wolfBooking}
          >
            <BasketTotalContainer
              onBookNowClick={this.handleBookNowClick}
              isCreatingReservation={this.props.isCreatingReservation}
              wolfBooking={this.props.wolfBooking}
              footerButtonColor={this.props.footerButtonColor}
            />
          </FooterBar>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const performanceId = parseInt(match.params.performanceId, 10);
  const { eventSlug, lng } = match.params;
  return {
    performanceId,
    eventSlug,
    lng,
    products: productSelectors.getProducts(state, performanceId),
    productsLoading: productSelectors.getLoading(state, performanceId),
    basket: basketSelectors.getBasket(state),
    numberOfItems: basketSelectors.getBasketItemCount(state),
    isCreatingReservation: reservationSelectors.getIsCreating(state),
    sessionId: reservationSelectors.getSessionId(state)
  };
};

export default connect(
  mapStateToProps,
  {
    ...productActions,
    ...basketActions,
    ...reservationActions
  }
)(ProductTableContainer);
