import React from 'react';
import Button from '../../components/Button/Button';
import DWTFFooter from '../../components/DWTFFooter/DWTFFooter';
import MainContent, {
  ContentContainer,
  PageContentBlock,
  TextBlock
} from '../../components/MainContent/MainContent';
import DWTFLogoLarge from '../../images/doctor-who/DoctorWho_logo_large.png';

const DoctorWhoUpdate = ({ match }) => {
  return (
    <div className='App App--gatsby App--gatsby-terms'>
      <MainContent gatsby>
        <ContentContainer shadow='true' gatsby='true'>
          <PageContentBlock>
            <a className='terms-logo' href='/'>
              <img
                src={DWTFLogoLarge}
                alt='Doctor Who: Time Fracture'
                className='full-image-background-cover__content-image'
              />
            </a>
            {/* <h2 className='gatsby-h2'>Update</h2> */}

            <TextBlock toppadding>
              <h3 className='gatsby-h3'>
                Due to the recent government announcement, performances of
                Doctor Who: Time Fracture have been delayed by five weeks. The
                first performance is now scheduled for 6pm Wednesday 26th May
                2021.
              </h3>
              <div className='dw-content-divider'></div>
              <h4 className='gatsby-h4'>Direct Bookings</h4>
              <p>
                The Box Office are in the process of reallocating all affected
                bookings made through this website to ensure patrons are
                rebooked into equivalent performances in accordance with social
                distancing guidelines.
              </p>

              <p>
                This process will be complete by Friday 5th March 2021. If you
                have a booking that is affected by the delay to the performance
                schedule you will receive an email confirmation with your new
                performance details by this date.{' '}
              </p>

              <p>
                If you cannot attend your rebooked performance, you will be able
                to contact the Box Office to select a new date to see Doctor
                Who: Time Fracture, or alternatively you can cancel your tickets
                and receive a full refund. Details will follow in due course.
              </p>

              <p>
                <b>You do not have to take any further action at this time</b>,
                we thank you for your patience.
              </p>
              <div className='dw-content-divider'></div>
              <h4 className='gatsby-h4'>Agent Bookings</h4>
              <p>
                If you booked your ticket through a third party or Ticketing
                Agent, please contact their Customer Service team.
              </p>
              <p>
                Contact details for their Customer Service team will be on your
                original booking confirmation.
              </p>
            </TextBlock>
            <TextBlock toppadding>
              <div className='large-cta terms-cta'>
                <a href='/'>
                  <Button className='button--gatsby button--mobile-full'>
                    Back to Booking
                  </Button>
                </a>
              </div>
              <p className='credits'>
                BBC, DOCTOR WHO, TARDIS, DALEKS and CYBERMAN (word marks and
                logos) are trade marks of the British Broadcasting Corporation
                and are used under licence. BBC logo © BBC 1996. Doctor Who logo
                © BBC 2018. Dalek image © BBC/Terry Nation 1963. Cyberman image
                © BBC/Kit Pedler/Gerry Davis 1966. Licensed by BBC Studios.
              </p>
            </TextBlock>
          </PageContentBlock>
        </ContentContainer>
      </MainContent>

      <DWTFFooter />
    </div>
  );
};

export default DoctorWhoUpdate;
