import React from 'react';
import classNames from 'classnames';
import './FullBackgroundImageCoverContentWrap.css';

const FullBackgroundImageCoverContentWrap = ({
  children,
  topmargin,
  fadein,
  className,
  smalltopmargin
}) => {
  const FullBackgroundImageCoverContentWrapClass = classNames(
    'full-image-background-cover__content-wrap',
    className,
    {
      'full-image-background-cover__content-wrap--top-margin': topmargin,
      'full-image-background-cover__content-wrap--fade': fadein,
      'full-image-background-cover__content-wrap--small-top-margin': smalltopmargin
    }
  );
  return (
    <div className={FullBackgroundImageCoverContentWrapClass}>{children}</div>
  );
};

export default FullBackgroundImageCoverContentWrap;
