import React from 'react';
import { Link as ScrollLink } from 'react-scroll';
import Button from '../../Button/Button';
import './FooterMenu.css';
import SafeIcon from '../../../images/SOLT_SIS_icon.png';

const FooterMenu = ({
  showBook = true,
  gatsbyButton = true,
  showTerms = true,
  footerButtonColor,
  links = []
}) => {
  const styles = {
    backgroundColor: `${footerButtonColor}`
  };
  return (
    <div className='footer-menu'>
      {links.map(link => (
        <ScrollLink
          key={link.to}
          to={link.to}
          smooth='easeInOutQuad'
          offset={0}
          duration={1000}
          className={
            link.special === true
              ? 'footer-menu__link--special'
              : 'footer-menu__link'
          }
          activeClass='footer-menu__link--active'
          spy={true}
          hashSpy={true}
        >
          {link.label}
        </ScrollLink>
      ))}
      {showTerms && (
        <>
          <a href='/faq' className='footer-menu__link'>
            FAQ
          </a>
          <a href='/cast-creative' className='footer-menu__link'>
            Cast & Creative
          </a>
          <a href='/merchandise' className='footer-menu__link'>
            Merchandise
          </a>
          <a href='/terms' className='footer-menu__link'>
            Terms
          </a>
          <a href='/privacy-policy' className='footer-menu__link'>
            Privacy
          </a>
          <a href='/cookie-policy' className='footer-menu__link'>
            Cookies
          </a>
        </>
      )}
      {showBook && (
        <ScrollLink
          to='booking'
          smooth='easeInOutQuad'
          offset={0}
          duration={1000}
          className='footer-menu__button'
          spy={true}
          hashSpy={true}
        >
          {gatsbyButton ? (
            <div className='footer-menu__book'>
              <span className='footer-menu__safe-icon'>
                <img
                  src={SafeIcon}
                  className='footer-menu__safe-icon-image'
                  alt='See It Safely'
                />
              </span>
              <Button className='button--gatsby button--full'>Book</Button>
            </div>
          ) : (
            <div className='footer-menu__book'>
              <Button style={styles} className='button--full'>
                Book
              </Button>
            </div>
          )}
        </ScrollLink>
      )}
    </div>
  );
};
export default FooterMenu;
