import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import AccessibilityAccordion from './AccessibilityAccordion';
import './Accordion.css';
import { getAccordionPreExpanded } from './getAccordionPreExpanded';

const AcessibilityAccordionHeader = () => {
  const preExpanded = getAccordionPreExpanded('accessibility');

  return (
    <div id='accessibility'>
      <Accordion
        className='accordion'
        allowZeroExpanded
        preExpanded={preExpanded}
      >
        <AccordionItem uuid='accessibility'>
          <AccordionItemHeading>
            <AccordionItemButton>
              <h3 className='gatsby-h3'>Accessibility</h3>
              <div className='accordion__arrow' />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <AccessibilityAccordion />
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default AcessibilityAccordionHeader;
