import React from 'react';
import DiscountForm from './DiscountForm/DiscountForm';
import Button from '../../../Button/Button';
import './DiscountCode.css';
import classNames from 'classnames';

const DiscountCode = ({
  shouldShowDiscountForm,
  hideDiscountForm,
  showDiscountForm,
  onDiscountSubmit,
  className,
  wolfBooking
}) => {
  const handleHideDiscountForm = ev => {
    ev.preventDefault();
    hideDiscountForm && hideDiscountForm();
  };
  const handleShowDiscountForm = ev => {
    ev.preventDefault();
    showDiscountForm && showDiscountForm();
  };
  const discountButtonClass = classNames('discount__button', className, {
    'discount__button--wolf': wolfBooking
  });
  return shouldShowDiscountForm ? (
    <span className="discount">
      <span className="discount__header">
        <span className="discount__text">Add Voucher / Promo code below</span>
        <Button
          className={discountButtonClass}
          onClick={handleHideDiscountForm}
        >
          Cancel
        </Button>
      </span>
      <DiscountForm onSubmit={onDiscountSubmit} wolfBooking={wolfBooking} />
    </span>
  ) : (
    <span className="discount">
      <Button className={discountButtonClass} onClick={handleShowDiscountForm}>
        Add Voucher / Promo Code
      </Button>
    </span>
  );
};

export default DiscountCode;
