import React from 'react';
import Button from '../../components/Button/Button';
import DWTFFooter from '../../components/DWTFFooter/DWTFFooter';
import MainContent, {
  ContentContainer,
  PageContentBlock,
  TextBlock
} from '../../components/MainContent/MainContent';
import DWTFLogoLarge from '../../images/doctor-who/DoctorWho_logo_large.png';

const DoctorWhoPrivacy = ({ match }) => {
  return (
    <div className='App App--gatsby App--gatsby-terms'>
      <MainContent gatsby>
        <ContentContainer shadow='true' gatsby='true'>
          <PageContentBlock>
            <a className='terms-logo' href='/'>
              <img
                src={DWTFLogoLarge}
                alt='Doctor Who: Time Fracture'
                className='full-image-background-cover__content-image'
              />
            </a>
            <h2 className='gatsby-h2'>Privacy Policy</h2>

            <TextBlock full textleft toppadding>
              <p>
                This Privacy Policy governs the manner in which Immersive
                Everywhere and partner companies collect, use, maintain and
                disclose information collected from users (each, a “User”) of
                the https://immersivedoctorwho.com website (“Site”). This
                privacy policy applies to the Site and all products and services
                offered by Immersive Everywhere and partner companies.
              </p>

              <h3 className='gatsby-h3'>Personal Data</h3>

              <p>
                We may collect personal data from Users in a variety of ways
                such as when Users visit our site, register on the site, place
                an order, subscribe, fill out a form, and in connection with
                other activities, services, features or resources we make
                available on our Site. Users may be asked for name, email
                address, mailing address, phone number, credit card information
                and address. Users may, however, visit our Site anonymously. We
                will collect personal identification information from Users only
                if they voluntarily submit such information to us. Users can
                always refuse to supply personal data which will affect parts of
                the service which rely on that data.
              </p>

              <h3 className='gatsby-h3'>Non-Personal Data</h3>

              <p>
                We may collect non-personal data about Users whenever they
                interact with our Site. Non-personal identification information
                may include the browser name, the type of computer and technical
                information about Users’ means of connection to our Site, such
                as the operating system and the Internet service providers
                utilised and other similar information.
              </p>

              <h3 className='gatsby-h3'>Tracking And Cookies</h3>

              <p>
                Our Site uses functions such as “cookies” to track information
                and for record-keeping purposes which will influence the
                experience of that User, for instance by providing more relevant
                marketing. Users may choose to set their web browser to refuse
                cookies, or to alert them when cookies are being sent. If they
                do so, some parts of the Site may function differently. If any
                part of the Site fails to function as a result of this please
                let us know.
              </p>

              <h3 className='gatsby-h3'>How We Use This Information</h3>

              <p>
                We may collect and use Users’ personal information for the
                following purposes:
                <ul>
                  <li>
                    To improve customer service: Information you provide helps
                    us respond to your customer service requests and support
                    needs more efficiently
                  </li>
                  <li>
                    To personalise user experience: We may display Information
                    which we think is more likely to be of interest to you.{' '}
                  </li>
                  <li>
                    To analyse our Site’s performance: We may use information in
                    the aggregate to understand how our Users as a group use the
                    services and resources provided on our Site.{' '}
                  </li>
                  <li>
                    To improve our Site: We may use feedback you provide to
                    improve our products and services.{' '}
                  </li>
                  <li>
                    To process payments: We may use the information Users
                    provide about themselves when placing an order only to
                    provide service to that order. We do not share this
                    information with outside parties except to the extent
                    necessary to provide the service.{' '}
                  </li>
                  <li>
                    For essential communication relating to a purchase: We will
                    use the email address to send the User email confirmation
                    pertaining to their order, such as the location of the
                    event, changes to the schedule or other important
                    information.
                  </li>
                </ul>
              </p>
              <p>
                It may also be used to respond to the User’s inquiries,
                questions, and/or other requests. In addition, a User may choose
                to opt-in to a mailing list or other subscription, they will
                receive emails that may include company news, updates, related
                product or service information, etc. Users may unsubscribe at
                any time by contacting us or using the link at the bottom of
                these emails.
              </p>

              <h3 className='gatsby-h3'>How We Protect Your Information</h3>

              <p>
                We adopt appropriate data collection, storage and processing
                practices and security measures to protect against unauthorised
                access, alteration, disclosure or destruction of your personal
                information, username, password, transaction information and
                data stored on our Site. Sensitive and private data exchange
                between the Site and its Users happens over a SSL secured
                communication channel and is encrypted and protected with
                digital signatures.
              </p>

              <h3 className='gatsby-h3'>Data Sharing</h3>

              <p>
                We do not sell, trade, or rent Users personal identification
                information to others. We share generic aggregated demographic
                information (but not personal data) with our business partners,
                such as Producers and marketing providers to make marketing
                decisions. We use third party service providers to help us
                operate our business and the Site or administer activities on
                our behalf, such as sending out newsletters or surveys. We may
                share your information with these third parties for those
                purposes. We will only transfer information outside the EEA
                where the organisation receiving the personal data has provided
                adequate safeguards in accordance with the requirements of GDPR.
              </p>

              <h3 className='gatsby-h3'>Giving You Control</h3>

              <p>
                You can have access to your data and update your preferences by
                logging in and changing them. Alternatively, you can email us or
                call us and we can give you this information or make these
                changes for you (note we cannot remove contact information from
                your account while your attendance at a performance is pending
                as this information is contractually required). You may request
                that your data is transferred to a third party.
              </p>

              <p>
                If you are unhappy with any aspect of our data policy you may
                make a complaint to the directors of the company who will
                personally investigate the issue. Please email
                ticketing@artstickets.co.uk noting ‘FAO the Directors’ in the
                subject line.
              </p>

              <p>
                If you are still unhappy you have the right to complain to a
                supervisory body such as the ICO who can impose fines and
                sanctions on companies in breach of the relevant regulations.
              </p>

              <h3 className='gatsby-h3'>Fair Processing Of Data</h3>

              <p>
                We ordinarily process data on the basis that our Users are
                visiting our site because they have an interest in interacting
                with us. We collect limited data and make limited use of it in
                order to serve the interest which Users have in interacting with
                us.
              </p>

              <p>
                Where a User consents to receive marketing communications from
                us by opting-in to our mailing list, we will send these
                communications. It is possible to unsubscribe instantly.
              </p>

              <p>
                In the case of a User purchasing a product from us, we
                contractually require some personal data in order to complete
                the transaction and provide key information. Once the contract
                has been fulfilled (for instance, after you have attended a play
                or once your membership expires), we will normally keep your
                data because we believe you may be interested in returning to us
                for a similar product, but you may change these preferences at
                any time.
              </p>

              <h3 className='gatsby-h3'>Lottery and Prize Draws</h3>

              <p>
                We rely on the consent of entrants to manage and conduct lottery
                and prize draws. Entrants can withdraw such consent at any time
                by contacting{' '}
                <a href='mailto:ticketing@artstickets.co.uk'>
                  ticketing@artstickets.co.uk
                </a>
                , after which your data will be deleted.
              </p>

              <h3 className='gatsby-h3'>Changes To This Privacy Policy</h3>

              <p>
                We have the discretion to update this privacy policy at any
                time. When we do, we will revise the updated date at the bottom
                of this page. We encourage Users to frequently check this page
                for any changes to stay informed about how we are helping to
                protect the personal information we collect. You acknowledge and
                agree that it is your responsibility to review this privacy
                policy periodically and become aware of modifications.
              </p>

              <h3 className='gatsby-h3'>Your Acceptance Of These Terms</h3>

              <p>
                By using this Site, you signify your acceptance of this policy.
                If you do not agree to this policy, please do not use our Site.
                Your continued use of the Site following the posting of changes
                to this policy will be deemed your acceptance of those changes.
              </p>

              <h3 className='gatsby-h3'>Contacting Us</h3>
              <p>
                If you have any questions about these terms, the practices of
                this site, or your dealings with this site, please contact us
                at:
                <br />
                <br />
                Immersive Everywhere
                <br />
                <a href='http://www.immersiveeverywhere.com'>
                  http://www.immersiveeverywhere.com
                </a>
                <br />
                020 7371 5444
                <br />
                <a href='mailto:ticketing@artstickets.co.uk'>
                  ticketing@artstickets.co.uk
                </a>
                <br />
              </p>
            </TextBlock>
            <TextBlock toppadding>
              <div className='large-cta terms-cta'>
                <a href='/'>
                  <Button className='button--gatsby button--large button--mobile-full'>
                    Back to Booking
                  </Button>
                </a>
              </div>
            </TextBlock>
            <TextBlock toppadding>
              <p className='credits'>
                BBC, DOCTOR WHO, TARDIS, DALEKS and CYBERMAN (word marks and
                logos) are trade marks of the British Broadcasting Corporation
                and are used under licence. BBC logo © BBC 1996. Doctor Who logo
                © BBC 2018. Dalek image © BBC/Terry Nation 1963. Cyberman image
                © BBC/Kit Pedler/Gerry Davis 1966. Licensed by BBC Studios.
              </p>
            </TextBlock>
          </PageContentBlock>
        </ContentContainer>
      </MainContent>

      <DWTFFooter />
    </div>
  );
};

export default DoctorWhoPrivacy;
