import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import './Accordion.css';

const FAQAccordion = () => {
  return (
    <Accordion className='accordion' allowZeroExpanded>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>Is your venue wheelchair accessible?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Yes, we are able to provide a wheelchair track at the 12pm or 6pm
            time slots. Patrons will be accompanied by a member of Front of
            House and a Time Lord Guide. We kindly ask wheelchair patrons to
            book tickets by calling Arts Tickets on 0203 053 1500.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>Is the venue covid-19 secure?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>Yes, Immersive | LDN is a covid-19 secure venue.</p>
        </AccordionItemPanel>
      </AccordionItem>

      {/* <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              What's happening with Captain Jack Harkness and Torchwood in the
              show?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Immersive Everywhere have taken the decision to remove this pre
            record from Doctor Who: Time Fracture. We will continue to include
            content that pays tribute to this brilliant show that is Torchwood
            so as not to disappoint its fans, and are working on an exciting
            storyline to be announced soon.
          </p>
        </AccordionItemPanel>
      </AccordionItem> */}

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>How long is the production?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Including an interval, the production will be 2 hours 15 mins in
            length.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>What is an immersive production?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            One of the fastest growing forms of live entertainment! Audiences
            become participants in an experience rather than purely spectators
            at a show. We build detailed worlds that audiences can explore at
            their leisure. Productions feature multiple spaces, big and small,
            all with working features and amenities.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>What is the recommended minimum age?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            8+. This adventure contains monsters, aliens and the kind of peril
            that every young fan would expect to encounter. Parents and
            guardians with children are asked to consider this when booking
            their tickets.
            <em>Anyone aged 16 and under must be accompanied by an adult</em>.
            While all shows feature the same content, we have a range of matinee
            performances that are particularly suitable for families.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              Is there any content I need to be warned about?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Please find our content warnings detailed on our{' '}
            <a
              href='/specific-requirements'
              className='faq_link'
              target='_blank'
              rel='noopener noreferrer'
            >
              Specific Requirements page
            </a>
            . If you have any queries that have not been answered by the
            information on this page, please contact{' '}
            <a href='mailto:ticketing@artstickets.co.uk' className='faq_link'>
              ArtsTickets
            </a>{' '}
            for further assistance.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              Are there any special effects that I should be aware of?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            The experience features strobe lighting and sound effects. Of
            particular note: loud noise, alarms, gunfire and explosive sounds.
            Auditory sensitivities or concerns should be raised with a member of
            staff before the show begins.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>Is there a cloakroom?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            There will be a cloakroom available on site for coats and small
            items. Unfortunately, the room is not bigger on the inside so please
            leave larger items at home. There will be a small fee to use the
            cloakroom and this is payable by card or contactless device only.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>Does the bar take cash or card?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            The venue will be entirely cash-free. The cloakroom, merchandise
            kiosks and the bar are all card or contactless device only.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>Do you offer any booking packages?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>Not at present, but please check back for updates.</p>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default FAQAccordion;
