import React, { Component } from 'react';
import ReactMapGL, { Marker, NavigationControl } from 'react-map-gl';
import './Mapbox.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Mapbox extends Component {
  state = {
    viewport: {
      width: window.innerWidth,
      height: 500,
      latitude: 51.513147,
      longitude: -0.148094,
      zoom: 16
    }
  };

  render() {
    return (
      <div className='mapbox'>
        <ReactMapGL
          className='mapbox-map'
          showZoomControls={true}
          mapStyle='mapbox://styles/mapbox/dark-v9'
          mapboxApiAccessToken='pk.eyJ1IjoibGluZXVwbm93IiwiYSI6Ik15UE1jMjgifQ.qezUWWAOK9ZOwg3KX_GPEQ'
          {...this.state.viewport}
          onViewportChange={viewport => this.setState({ viewport })}
          scrollZoom={false}
        >
          <Marker
            latitude={51.513147}
            longitude={-0.148094}
            offsetLeft={-20}
            offsetTop={-50}
          >
            <div className='mapbox-marker gold-font'>
              <FontAwesomeIcon
                icon='map-marker'
                fixedWidth
                className='icon icon--map icon--no-margin'
              />
            </div>
          </Marker>
          <div className='mapbox-control'>
            <NavigationControl
              showCompass={false}
              onViewportChange={viewport => this.setState({ viewport })}
            />
          </div>
        </ReactMapGL>
      </div>
    );
  }
}

export default Mapbox;
