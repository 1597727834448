import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { withRouter } from 'react-router';

import CalendarContainer from './Calendar/CalendarContainer';
import TicketTableContainer from './Tickets/TicketTableContainer';
import ProductTableContainer from './Products/ProductTableContainer';

import ConfirmOrderContainer from './ConfirmOrder/ConfirmOrderContainer';

import SuccessContainer from './OrderSuccess/SuccessContainer';
import SelectTimeContainer from './Performances/SelectTimeContainer';
import ReservationExpiredContainer from './ReservationExpired/ReservationExpiredContainer';

import './Booking.css';

let STRIPE_API_KEY;
let STRIPE_ACCOUNT_ID;

switch (process.env.REACT_APP_API_VERSION) {
  case 'testing':
    STRIPE_API_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY_TESTING;
    STRIPE_ACCOUNT_ID = process.env.REACT_APP_STRIPE_ACCOUNT_ID_TESTING;
    break;
  case 'live':
    STRIPE_API_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY_LIVE;
    STRIPE_ACCOUNT_ID = process.env.REACT_APP_STRIPE_ACCOUNT_ID_LIVE;
    break;
  default:
    STRIPE_API_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY_TESTING;
    STRIPE_ACCOUNT_ID = process.env.REACT_APP_STRIPE_ACCOUNT_ID_TESTING;
}

const stripePromise = loadStripe(STRIPE_API_KEY, {
  stripeAccount: STRIPE_ACCOUNT_ID
});

const elementsOptions = {
  fonts: [
    {
      cssSrc: 'https://fonts.googleapis.com/css?family=Roboto:400,700'
    }
  ]
};

class Booking extends Component {
  render() {
    const { includePassword } = this.props;
    const footerLinks = this.props.footerLinks;
    const footerBackgroundColor = this.props.footerBackgroundColor;
    const footerBorderColor = this.props.footerBorderColor;
    const footerLinkColor = this.props.footerLinkColor;
    const footerButtonColor = this.props.footerButtonColor;
    const footerFontFamily = this.props.footerFontFamily;
    const gatsbyButton = this.props.gatsbyButton;
    const gatsbyBooking = this.props.gatsbyBooking;
    const wolfBooking = this.props.wolfBooking;
    const wolfButton = this.props.wolfButton;
    const calendarTitle = this.props.calendarTitle;
    return (
      <Elements stripe={stripePromise} options={elementsOptions}>
        <Switch>
          <Route
            exact
            path='/:lng(cn|en)?/event/:eventSlug'
            render={props => (
              <CalendarContainer
                {...props}
                footerLinks={footerLinks}
                footerBackgroundColor={footerBackgroundColor}
                footerBorderColor={footerBorderColor}
                footerButtonColor={footerButtonColor}
                footerLinkColor={footerLinkColor}
                footerFontFamily={footerFontFamily}
                gatsbyButton={gatsbyButton}
                wolfButton={wolfButton}
                gatsbyBooking={gatsbyBooking}
                wolfBooking={wolfBooking}
                calendarTitle={calendarTitle}
              />
            )}
          />
          <Route
            exact
            path={`/:lng(cn|en)?/event/:eventSlug/:performanceId/tickets`}
            render={props => (
              <TicketTableContainer
                {...props}
                minimumQuantity={this.props.minimumQuantity}
                footerFontFamily={footerFontFamily}
                gatsbyBooking={gatsbyBooking}
                wolfBooking={wolfBooking}
              />
            )}
          />
          <Route
            exact
            path={`/:lng(cn|en)?/event/:eventSlug/:performanceId/products`}
            render={props => (
              <ProductTableContainer
                {...props}
                footerFontFamily={footerFontFamily}
                gatsbyBooking={gatsbyBooking}
                wolfBooking={wolfBooking}
              />
            )}
          />
          <Route
            exact
            path={`/:lng(cn|en)?/event/:eventSlug/:date/select-time`}
            render={props => (
              <SelectTimeContainer
                {...props}
                footerFontFamily={footerFontFamily}
                gatsbyBooking={gatsbyBooking}
                wolfBooking={wolfBooking}
              />
            )}
          />
          <Route
            exact
            path='/:lng(cn|en)?/event/:eventSlug/confirm'
            render={props => (
              <ConfirmOrderContainer
                {...props}
                footerFontFamily={footerFontFamily}
                gatsbyBooking={gatsbyBooking}
                wolfBooking={wolfBooking}
                includePassword={includePassword}
              />
            )}
          />

          <Route
            exact
            path='/:lng(cn|en)?/event/:eventSlug/success'
            render={props => (
              <SuccessContainer
                {...props}
                footerLinks={footerLinks}
                footerBackgroundColor={footerBackgroundColor}
                footerBorderColor={footerBorderColor}
                footerLinkColor={footerLinkColor}
                footerFontFamily={footerFontFamily}
                gatsbyBooking={gatsbyBooking}
                wolfBooking={wolfBooking}
              />
            )}
          />
          <Route
            exact
            path='/:lng(cn|en)?/event/:eventSlug/reservation-expired'
            component={ReservationExpiredContainer}
          />
        </Switch>
      </Elements>
    );
  }
}

export default withRouter(Booking);
