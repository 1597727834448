import React from 'react';
import Imgix, { Picture, Source } from 'react-imgix';
import './ProductRow.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

/* eslint-disable react/style-prop-object */
import { FormattedNumber } from 'react-intl';
import Button from '../../../Button/Button';
import classNames from 'classnames';

import './ProductRow.css';

const ProductRow = ({
  productId,
  name,
  description,
  total,
  currency,
  image,
  quantityAvailable,
  quantitySelected,
  trackInventory,
  onAddToBasketClick,
  onRemoveFromBasketClick,
  className,
  wolfBooking,
}) => {
  const quantityLeft = quantityAvailable - quantitySelected;
  const soldOut = quantityAvailable <= 0;
  const imageUrl = image ? image.url : null;
  const handleAddToBasketClick = (evt) => {
    evt.preventDefault();
    if (!soldOut && quantityLeft > 0) {
      onAddToBasketClick(productId);
    }
  };

  const handleRemoveFromBasketClick = (evt) => {
    evt.preventDefault();
    evt.stopPropagation();
    if (quantitySelected > 0) {
      onRemoveFromBasketClick(productId);
    }
  };

  const rowGroupClass = classNames('product-row', className, {
    'product-row--in-basket': quantitySelected > 0,
    'product-row--sold-out': soldOut,
  });

  const ticketControlClass = classNames(
    className,
    'product-row__control-button',
    {
      'product-row__control-button--disabled': quantityLeft === 0,
    }
  );

  return (
    <span className={rowGroupClass} onClick={handleAddToBasketClick}>
      <span className='product-row__image'>
        {imageUrl && (
          <Picture>
            <Source
              src={imageUrl}
              sizes='calc(100%)'
              htmlAttributes={{ media: '(max-width: 479px)' }}
            />
            <Imgix src={imageUrl} width={150} />
          </Picture>
        )}
      </span>
      <div className='product-row-details'>
        <span className='product-row__name'>{name}</span>
        {description && (
          <span className='product-row__description'>{description}</span>
        )}
      </div>
      <span className='product-row__price'>
        <FormattedNumber value={total} style='currency' currency={currency} />
      </span>
      {soldOut ? (
        <span className='product-row__controls product-row__controls--sold-out'>
          <span className='sold-out-label'>Sold Out</span>
        </span>
      ) : (
        <span className='product-row__controls'>
          <div className='product-row__button product-row__button--minus'>
            <Button
              onClick={handleRemoveFromBasketClick}
              className={ticketControlClass}
            >
              <FontAwesomeIcon icon='minus' fixedWidth className='' />
            </Button>
          </div>
          <span className='product-row__quantity'>{quantitySelected}</span>
          <div className='product-row__button product-row__button--plus'>
            <Button
              disabled={quantityLeft === 0 ? 'disabled' : ''}
              className={ticketControlClass}
            >
              <FontAwesomeIcon icon='plus' fixedWidth className='' />
            </Button>
          </div>
        </span>
      )}
    </span>
  );
};

export default ProductRow;
