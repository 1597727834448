import React from 'react';
import { CardElement } from '@stripe/react-stripe-js';
import './CardSection.css';

const CardSection = ({ errorMessage }) => (
  <div className='form__row-container'>
    <div className='form__row'>
      <CardElement
        className='form__field-input'
        options={{
          style: {
            base: {
              fontSize: '16px',
              fontFamily: '"Roboto", Helvetica, sans-serif',
              color: '#1f1f1f',
              fontSmoothing: 'antialiased',
              '::placeholder': {
                color: '#999'
              }
            },
            invalid: { color: '#ff3323', iconColor: '#e0395c' }
          },
          hidePostalCode: true
        }}
      />
      {errorMessage && <div class='form__error'>{errorMessage}</div>}
    </div>
  </div>
);

export default CardSection;
