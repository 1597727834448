import React from 'react';
import { Field, reduxForm } from 'redux-form';
import Form from '../../Form/Form';
import FormField from '../../Form/FormField/FormField';

const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  return errors;
};

let ForgotPasswordForm = ({
  error,
  handleSubmit,
  pristine,
  submitting,
  wolfBooking
}) => {
  return (
    <Form
      handleSubmit={handleSubmit}
      error={error}
      submitText={'Reset my password'}
      wolfBooking={wolfBooking}
    >
      <Field
        name="email"
        component={FormField}
        type="email"
        label="Email"
        placeholder="Email address"
        autoComplete="email"
        hideLabel={true}
      />
    </Form>
  );
};

ForgotPasswordForm = reduxForm({
  form: 'forgotPassword',
  validate
})(ForgotPasswordForm);

export default ForgotPasswordForm;
