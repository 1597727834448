import { useState, useEffect } from 'react';

export function useCardBrand(elements, elementName = 'card') {
  const [cardBrand, setCardBrand] = useState('unknown');

  useEffect(() => {
    elements.getElement(elementName).on('change', e => {
      setCardBrand(e.brand);
    });
  }, [elements, elementName]);

  return cardBrand;
}
