import React from 'react';
import Button from '../../components/Button/Button';
import DWTFFooter from '../../components/DWTFFooter/DWTFFooter';
import MainContent, {
  ContentContainer,
  PageContentBlock,
  TextBlock
} from '../../components/MainContent/MainContent';
import DWTFLogoLarge from '../../images/doctor-who/DoctorWho_logo_large.png';

import SeeItSafely2022 from '../../images/doctor-who/see_it_safely_2022.png';

const DoctorWhoUpdate = ({ match }) => {
  return (
    <div className='App App--gatsby App--gatsby-terms'>
      <MainContent gatsby>
        <ContentContainer shadow='true' gatsby='true'>
          <PageContentBlock>
            <a className='terms-logo' href='/'>
              <img
                src={DWTFLogoLarge}
                alt='Doctor Who: Time Fracture'
                className='full-image-background-cover__content-image'
              />
            </a>
            {/* <h2 className='gatsby-h2'>Update</h2> */}

            <TextBlock toppadding full>
              <h2 className='gatsby-h2'>COVID-19, See it Safely</h2>

              <p>
                The safety of our audience, performers and staff is our
                priority. Please find below the measures we have in place but
                please note that these are subject to change to keep in line
                with Government guidance.
              </p>

              <p>Thank you for your full co-operation.</p>

              <div className='see-it-safely-banner'>
                <a className='faq_link' href='/see-it-safely'>
                  <img
                    className='see-it-safely-banner-image'
                    src={SeeItSafely2022}
                    alt='See It Safely Logo'
                  />
                </a>
              </div>

              <p>
                As an immersive show, and with our audience frequently in close
                proximity with our performers, we respectfully ask the following
                of our patrons:
              </p>
            </TextBlock>
            <TextBlock toppadding textleft full>
              <div className='specific-requirements-section'>
                <h3 className='gatsby-h3'>Mask wearing</h3>
                <p>
                  Wear a mask at all times when inside the building, unless you
                  are exempt.
                </p>

                <p>
                  Whilst it is possible to remove your mask to eat and drink, we
                  would ask that you remain respectful of the performers and
                  keep your mask on when you are in close proximity to them
                </p>

                <p>
                  This may also include any side rooms or smaller performance
                  spaces that you may be taken into and we would ask that no
                  drinks are consumed in these smaller spaces with masks
                  remaining on at all times
                </p>
              </div>

              <div className='specific-requirements-section'>
                <h3 className='gatsby-h3'>
                  Lateral Flow Tests/Proof of vaccination status
                </h3>
                <p>
                  Upon arrival at the venue, we ask our patrons to provide one
                  of the following:
                </p>

                <ul className='left-text'>
                  <li>
                    Proof of a negative Lateral Flow Test taken within the
                    previous 48 hours
                  </li>

                  <li>
                    Proof of double vaccination via the NHS App or vaccination
                    cards
                  </li>

                  <li>
                    Proof of medical exemption or you are taking part in a
                    clinical trial
                  </li>
                </ul>

                <p>
                  Our security staff will be checking these, prior to entry into
                  the building, so we would ask that all members of your party
                  have them to hand as you approach the entrance for the sake of
                  speed.
                </p>

                <p>The easiest way is via the NHS App.</p>

                <p>
                  Unfortunately,{' '}
                  <b>
                    <u>no exceptions</u>
                  </b>{' '}
                  can be made to this rule for patrons aged 12 and over. This is
                  in place to protect our staff, our performers and our audience
                  members.
                </p>

                <p>
                  If you are unable to provide proof then you will be denied
                  entry to the venue.
                </p>
              </div>

              <div className='specific-requirements-section'>
                <h3 className='gatsby-h3'>Contactless Payments</h3>
                <p>
                  We only accept payments within the venue via contactless
                  payments.
                </p>
              </div>

              <div className='specific-requirements-section'>
                <h3 className='gatsby-h3'>Enhanced level of cleaning</h3>
                <p>
                  We deliver an enhanced level of cleaning throughout our
                  venues.
                </p>
              </div>

              <div className='specific-requirements-section'>
                <h3 className='gatsby-h3'>Hand Sanitiser</h3>
                <p>
                  Hand Sanitiser stations are situated upon entry at the venue
                  as well as throughout. We encourage our audience to use these
                  regularly. Please ask a member of staff if you are unable to
                  locate one.
                </p>
              </div>

              <div className='specific-requirements-section'>
                <h3 className='gatsby-h3'>Ventilation System</h3>
                <p>
                  Enhanced ventilation systems are in place throughout our
                  venues.
                </p>
              </div>

              <div className='specific-requirements-section'>
                <h3 className='gatsby-h3'>Ticketing</h3>
                <p>
                  We offer a “no questions asked” policy on exchanging tickets,
                  up to 2 hours prior to the performance.
                </p>

                <p>
                  All exchanges must be completed prior to the start time of the
                  performance you have booked. Changes cannot be made once the
                  performance has started.
                </p>

                <p>
                  Please do contact Arts Tickets if you need to exchange and are
                  unable to attend the performance.
                </p>

                <p>All of our ticketing is contactless.</p>
              </div>

              <div className='specific-requirements-section'>
                <h3 className='gatsby-h3'>Our Performers and Staff</h3>
                <p>
                  Our performers and staff are tested on a daily basis to help
                  ensure the safety of everyone.
                </p>
              </div>
            </TextBlock>
            <TextBlock toppadding>
              <div className='large-cta terms-cta'>
                <a href='/'>
                  <Button className='button--gatsby button--mobile-full'>
                    Back to Booking
                  </Button>
                </a>
              </div>
              <p className='credits'>
                BBC, DOCTOR WHO, TARDIS, DALEKS and CYBERMAN (word marks and
                logos) are trade marks of the British Broadcasting Corporation
                and are used under licence. BBC logo © BBC 1996. Doctor Who logo
                © BBC 2018. Dalek image © BBC/Terry Nation 1963. Cyberman image
                © BBC/Kit Pedler/Gerry Davis 1966. Licensed by BBC Studios.
              </p>
            </TextBlock>
          </PageContentBlock>
        </ContentContainer>
      </MainContent>

      <DWTFFooter />
    </div>
  );
};

export default DoctorWhoUpdate;
