import React from 'react';
import Button from '../../components/Button/Button';
import Icon from '../../components/Icon/Icon';
import DWTFFooter from '../../components/DWTFFooter/DWTFFooter';
import MainContent, {
  ContentContainer,
  PageContentBlock,
  TextBlock
} from '../../components/MainContent/MainContent';
import DWTFLogoLarge from '../../images/doctor-who/DoctorWho_logo_large.png';

const DoctorWhoMerchandise = ({ match }) => {
  return (
    <div className='App App--gatsby App--gatsby-terms'>
      <MainContent gatsby>
        <ContentContainer shadow='true' gatsby='true'>
          <PageContentBlock>
            <a className='terms-logo' href='/'>
              <img
                src={DWTFLogoLarge}
                alt='Doctor Who: Time Fracture'
                className='full-image-background-cover__content-image'
              />
            </a>
            <h2 className='gatsby-h2'>Merchandise</h2>

            <TextBlock toppadding>
              <h3 className='gatsby-h3'>
                By clicking the link below you will be leaving a BBC hosted
                website and will be redirected to a third party site.
              </h3>
            </TextBlock>
            <TextBlock toppadding>
              <div className='large-cta terms-cta'>
                <a href='https://dwtf.probitymerch.com/'>
                  <Button className='button--gatsby button--mobile-full'>
                    <Icon name='external-link' />
                    View Merchandise
                  </Button>
                </a>
              </div>
              <div className='large-cta terms-cta'>
                <a href='/'>
                  <Button className='button-link-style'>Back to Booking</Button>
                </a>
              </div>
              <p className='credits'>
                BBC, DOCTOR WHO, TARDIS, DALEKS and CYBERMAN (word marks and
                logos) are trade marks of the British Broadcasting Corporation
                and are used under licence. BBC logo © BBC 1996. Doctor Who logo
                © BBC 2018. Dalek image © BBC/Terry Nation 1963. Cyberman image
                © BBC/Kit Pedler/Gerry Davis 1966. Licensed by BBC Studios.
              </p>
            </TextBlock>
          </PageContentBlock>
        </ContentContainer>
      </MainContent>

      <DWTFFooter />
    </div>
  );
};

export default DoctorWhoMerchandise;
