import React from 'react';
import { Field, reduxForm } from 'redux-form';
import FormField from '../../Form/FormField/FormField';
import Form from '../../Form/Form';
import './LoginForm.css';

const validate = values => {
  const errors = {};
  if (!values.email) {
    errors.email = 'Email address is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  if (!values.password) {
    errors.password = 'Password is required';
  } else if (values.password.length < 6) {
    errors.password = 'Your password needs to be at least 6 characters long';
  }
  return errors;
};

let LoginForm = ({
  error,
  handleSubmit,
  pristine,
  hideLabel,
  submitting,
  wolfBooking,
  useFooterButton
}) => {
  return (
    <Form
      submitting={submitting}
      pristine={pristine}
      handleSubmit={handleSubmit}
      error={error}
      wolfBooking={wolfBooking}
      useFooterButton={useFooterButton}
      submitText="Login"
    >
      <Field
        name="email"
        component={FormField}
        type="email"
        label="Email"
        hideLabel={true}
        placeholder="Email"
        autoComplete="email"
      />
      <Field
        name="password"
        component={FormField}
        type="password"
        label="Password"
        hideLabel={true}
        placeholder="Password"
      />
    </Form>
  );
};

LoginForm = reduxForm({
  form: 'auth',
  validate
})(LoginForm);

export default LoginForm;
