import React, { Fragment } from "react";

import MobileAvailability from "./MobileAvailability";

import "react-tippy/dist/tippy.css";

const Day = ({ day, performancesByDay, onPerformanceClick }) => {
  const performances = performancesByDay[day.getTime()];
  if (performances) {
    performances.sort((b, a) => b.startDate.unix() - a.startDate.unix());
  }

  return (
    <div>
      <div className="day-picker-day__date-number">{day.getDate()}</div>
      {performances && (
        <Fragment>
          <div className="day-picker-day__performances--mobile">
            <MobileAvailability performances={performances} />
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default Day;
