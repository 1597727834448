import React from 'react';
import { Field, reduxForm, SubmissionError, FormSection } from 'redux-form';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import FormField from '../../Form/FormField/FormField';
import CardSection from '../CardSection/CardSection';
import Form from '../../Form/Form';
import './PaymentForm.css';
import { useCardBrand } from '../../../utils/useCardBrand';

const validate = values => {
  const errors = { address: {} };
  if (!values.firstName) {
    errors.firstName = 'A first name is required';
  }
  if (!values.lastName) {
    errors.lastName = 'A last name is required';
  }
  if (!values.telephone) {
    errors.telephone = 'A phone number is required';
  } else if (!/^([+]|[0-9])([0-9]{8,15})$/i.test(values.telephone)) {
    errors.telephone = 'This appears to be an invalid phone number';
  }
  if (!values.address) {
    errors.address.postcode = 'A postcode or ZIP code is required';
  } else {
    if (!values.address.postcode) {
      errors.address.postcode = 'A postcode or ZIP code is required';
    }
    if (values.address.postcode.length < 5) {
      errors.address.postcode =
        'Your postcode/ZIP code needs to be at least 5 characters long';
    }
    if (values.address.postcode.length > 16) {
      errors.address.postcode =
        'Your postcode/ZIP code can be a maximum of 16 characters long';
    }
  }
  if (!values.email) {
    errors.email = 'An email address is required';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
  }
  console.log(errors);

  return errors;
};

let PaymentForm = props => {
  const {
    submitting,
    pristine,
    error,
    optInText,
    handleSubmit,
    onPaymentMethodSuccess,
    wolfBooking,
    total,
    showDetails
  } = props;
  const stripe = useStripe();
  const elements = useElements();
  const cardBrand = useCardBrand(elements);

  const handleStripeSubmit = values => {
    if (stripe && elements && cardBrand === 'amex') {
      const cardElement = elements.getElement(CardElement);

      const { firstName, lastName } = values;
      if (total <= 0) {
        return onPaymentMethodSuccess({}, values);
      }
      return stripe
        .createPaymentMethod({
          type: 'card',
          card: cardElement,
          billing_details: {
            name: `${firstName} ${lastName}`
          }
        })
        .then(({ paymentMethod, error }) => {
          if (error) {
            throw new SubmissionError({
              _error: error.message
            });
          } else {
            return onPaymentMethodSuccess(paymentMethod, values);
          }
        });
    } else {
      throw new SubmissionError({
        _error: 'There was a problem processing your order.'
      });
    }
  };

  return (
    <Form
      submitting={submitting}
      pristine={pristine}
      handleSubmit={handleSubmit(handleStripeSubmit)}
      error={error}
      submitText='Confirm & Pay'
      wolfBooking={wolfBooking}
    >
      <Field
        name='firstName'
        component={FormField}
        type='text'
        label='First Name'
        hideLabel={true}
        placeholder='First Name'
        autoComplete='given-name'
        narrowRow={true}
      />
      <Field
        name='lastName'
        component={FormField}
        type='text'
        label='Last Name'
        hideLabel={true}
        placeholder='Last Name'
        autoComplete='family-name'
        narrowRow={true}
      />
      {showDetails && (
        <>
          <Field
            name='email'
            component={FormField}
            label='Email Address'
            type='email'
            hideLabel={true}
            placeholder='Email Address'
            autoComplete='email'
          />
          <Field
            name='telephone'
            component={FormField}
            label='Telephone Number'
            hideLabel={true}
            type='tel'
            autoComplete='tel'
            placeholder='Phone Number'
          />
          <FormSection name='address'>
            <Field
              name='postcode'
              component={FormField}
              label='Postcode/Zip code'
              hideLabel={true}
              type='text'
              autoComplete='postal-code'
              placeholder='Postcode/ZIP code'
            />
          </FormSection>
        </>
      )}
      {total > 0 && (
        <CardSection
          errorMessage={
            cardBrand === 'amex' || cardBrand === 'unknown'
              ? null
              : 'Only American Express cards are accepted as part of this promotion'
          }
        />
      )}
      {optInText && (
        <Field
          name='optIn'
          component={FormField}
          type='checkbox'
          labelAfter={optInText}
        />
      )}
    </Form>
  );
};

PaymentForm = reduxForm({
  form: 'paymentForm',
  validate
})(PaymentForm);

export default PaymentForm;
