import React from "react";
import "./ProductTable.css";

const ProductTable = ({ products, renderProductRow, wolfBooking }) => {
  return (
    <div className="ticket-table-container">
      <div className="ticket-table">
        <span className="product__subtitle">
          Add some Official Doctor Who: Time Fracture merchandise:
        </span>
        <span className="product__table">{products.map(renderProductRow)}</span>
      </div>
    </div>
  );
};

export default ProductTable;
