import React from "react";
import Button from "../../components/Button/Button";
import DWTFFooter from "../../components/DWTFFooter/DWTFFooter";
import MainContent, {
  ContentContainer,
  PageContentBlock,
  TextBlock
} from "../../components/MainContent/MainContent";
import DWTFLogoLarge from "../../images/doctor-who/DoctorWho_logo_large.png";

const DoctorWhoTerms = ({ match }) => {
  return (
    <div className="App App--gatsby App--gatsby-terms">
      <MainContent gatsby>
        <ContentContainer shadow="true" gatsby="true">
          <PageContentBlock>
            <a className="terms-logo" href="/">
              <img
                src={DWTFLogoLarge}
                alt="Doctor Who: Time Fracture"
                className="full-image-background-cover__content-image"
              />
            </a>
            <h2 className="gatsby-h2">Terms & Conditions</h2>

            <TextBlock full textleft toppadding>
              <h3 className="gatsby-h3">Arts Tickets</h3>
              <p>
                Arts Tickets is an industry-leading ticketing platform designed
                to provide a world class, bespoke ticketing solution for the
                entertainment and hospitality industries. For more information,
                visit{" "}
                <a
                  href="https://artstickets.co.uk"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  ArtsTickets
                </a>
                .
              </p>

              <h3 className="gatsby-h3">Ticket Delivery / Collection</h3>

              <p>
                Arts Tickets sells for a variety of events with different
                policies. Most productions do not require paper tickets and run
                an electronic guestlist, however your email confirmation will
                contain all the information you need about how to access the
                event. Please take the debit or credit card you used for
                payment, or photo ID.
              </p>

              <h3 className="gatsby-h3">Security</h3>

              <p>
                For your comfort and security, you may be subject to additional
                checks on your visit to London theatres – we appreciate your
                patience and understanding while these are taking place.
              </p>

              <h3 className="gatsby-h3">Payment and Concessions</h3>

              <p>
                <b>PLEASE NOTE THERE IS NO RIGHT TO A REFUND OR EXCHANGE.</b>
              </p>
              <p>Please check your booking carefully before completing.</p>
              <p>
                Where an event is cancelled a full refund or exchange will be
                issued (at the choice of the customer) however please note that
                Arts Tickets are not liable to reimburse customers the cost of
                any related travel, accommodation or other charges incurred
                around your visit.
              </p>
              <p>
                Any requested refunds or exchanges, where an event has not been
                cancelled, are processed at the discretion of Arts Tickets once
                the circumstances surrounding the request have been fully
                assessed.
              </p>
              <p>
                Any changes or amendments to a booking must be completed prior
                to the performance originally booked. It is not possible to
                exchange or refund tickets for a performance that has passed (a
                performance is considered ‘passed’ once the start time on the
                date of the booking has been reached).
              </p>
              <p>
                Where a voucher refund has been issued, once the voucher code
                has been redeemed, tickets can be exchanged but not refunded.
              </p>
              <p>
                There may be an admin fee payable by the customer for changes
                made to tickets.
              </p>
              <p>Any applicable booking fees may be retained by Arts Tickets</p>
              <p>
                Arts Tickets cannot accept payment by cheque or Theatre Tokens.
              </p>

              <h3 className="gatsby-h3">Online</h3>

              <p>
                NOTE: To use our on-line booking you must enable Cookies on your
                browser.
              </p>

              <h3 className="gatsby-h3">In Person</h3>

              <p>There is no in-person box office facility at Arts Tickets.</p>

              <h3 className="gatsby-h3">Phone</h3>

              <p>
                We do not sell tickets over the phone, however for customer
                enquiries not answered by the website, please call us on 0203
                053 1500.
              </p>

              <h3 className="gatsby-h3">
                Bookings with specific requirements{" "}
              </h3>

              <p>
                Each venue we sell for has different accessibility information.
                Our booking path has been designed to enable customers to
                specify any requirements but for those who prefer to book by
                phone, please call us on 0203 053 1500.
              </p>

              <h3 className="gatsby-h3">Return Tickets</h3>

              <p>Return tickets may be accepted on sold out performances.</p>
              <p>
                Where exchanges are authorised, tickets may be exchanged for an
                alternative date of the same show and the exchange fee in force
                at the time will apply.
              </p>

              <h3 className="gatsby-h3">The Terms and Conditions</h3>

              <p>
                All venues reserve the right to refuse admission and request any
                ticket holder to leave the venue.
              </p>
              <p>
                The unauthorised use of photographic and recording equipment is
                prohibited. Mobile phones and any other electronic equipment
                must be switched off unless otherwise stated by the event
                organiser.
              </p>
              <p>
                From time to time events are cancelled without notice and Arts
                Tickets does not accept liability to pay any person compensation
                for damages other than a refund of the ticket price and any
                booking fees.
              </p>
              <p>
                There is a £2.95 booking fee on all tickets purchased online or
                over the phone.
              </p>
              <p>
                If you arrive late to a performance we cannot guarantee
                admission. Each production has different policies regarding
                latecomers and readmission.
              </p>
              <p>
                Some productions have a dynamic pricing structure and as such
                the price of a particular ticket may change. The price paid at
                the time of booking is final and non-negotiable.
              </p>

              <h3 className="gatsby-h3">Parental Guidance</h3>

              <p>
                8+. This adventure contains monsters, aliens and the kind of
                peril that every young fan would expect to encounter. Parents
                and guardians with children are asked to consider this when
                booking their tickets.
              </p>
              <p>
                <em>
                  Anyone aged 16 and under must be accompanied by an adult.
                </em>
              </p>
              <p>
                While all shows feature the same content, we have a range of
                matinee performances that are particularly suitable for
                families.
              </p>
              <p>
                ArtsTickets cannot guarantee admission or refund tickets for
                children who are unable to attend or where the ticket buyer
                deems the show unsuitable for children.
              </p>

              <h3 className="gatsby-h3">Contacting Us</h3>
              <p>
                If you have any questions about these terms, the practices of
                this site, or your dealings with this site, please contact us
                at:
                <br />
                <br />
                Arts Tickets
                <br />
                http://www.artstickets.co.uk
                <br />
                0203 053 1500
                <br />
                <a href="mailto:ticketing@artstickets.co.uk">
                  ticketing@artstickets.co.uk
                </a>
                <br />
              </p>
            </TextBlock>
            <TextBlock toppadding>
              <div className="large-cta terms-cta">
                <a href="/">
                  <Button className="button--gatsby button--large button--mobile-full">
                    Back to Booking
                  </Button>
                </a>
              </div>
            </TextBlock>
            <TextBlock toppadding>
              <p className="credits">
                BBC, DOCTOR WHO, TARDIS, DALEKS and CYBERMAN (word marks and
                logos) are trade marks of the British Broadcasting Corporation
                and are used under licence. BBC logo © BBC 1996. Doctor Who logo
                © BBC 2018. Dalek image © BBC/Terry Nation 1963. Cyberman image
                © BBC/Kit Pedler/Gerry Davis 1966. Licensed by BBC Studios.
              </p>
            </TextBlock>
          </PageContentBlock>
        </ContentContainer>
      </MainContent>

      <DWTFFooter />
    </div>
  );
};

export default DoctorWhoTerms;
