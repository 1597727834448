import React, { Fragment } from 'react';
import 'react-tippy/dist/tippy.css';
import { Tooltip } from 'react-tippy';
/* eslint-disable react/style-prop-object */
import { FormattedNumber } from 'react-intl';
import BSLIcon from '../../../images/bsl_icon.png';
import ADIcon from '../../../images/ad_icon.png';

const MobileAvailability = ({ performances, className }) => {
  const showAvailability = !performances.find(p => p.availability === null);

  const totalAvailability = showAvailability
    ? performances.reduce((a, b) => a + b.availability, 0) / performances.length
    : 0;
  const minPriceTicket = performances.reduce(
    (min, p) => (p.minTicketPrice < min ? p.minTicketPrice : min),
    performances[0].minTicketPrice
  );
  const soldOut = showAvailability ? totalAvailability === 0 : false;
  const availabilityWidth = showAvailability
    ? Math.max(parseInt((1 - totalAvailability) * 100, 10), 50)
    : 0;
  const performanceText =
    performances.length > 1
      ? `${performances.length} Times`
      : performances[0].startDate.format('h:mm a');

  return (
    <div className='mobile-availability'>
      <div className='mobile-availability__indicator'>
        <span className='mobile-availability__label'>
          <span className='mobile-availability__label-performance'>
            {soldOut ? 'Sold Out' : performanceText}
          </span>
          {soldOut ? (
            <span className='performance-info__price performance-info__price--sold-out'>
              {minPriceTicket && (
                <FormattedNumber
                  value={minPriceTicket}
                  style='currency'
                  currency={'GBP'}
                />
              )}
            </span>
          ) : (
            <span className='performance-info__price'>
              {minPriceTicket && (
                <>
                  From:{' '}
                  <FormattedNumber
                    value={minPriceTicket}
                    style='currency'
                    currency={'GBP'}
                  />
                </>
              )}
            </span>
          )}
        </span>
      </div>
      {performances.find(p => p.id === 6573812) && (
        <Fragment>
          <Tooltip
            title='This performance will incorporate BSL interpreters for those who require them'
            position='bottom'
            distance='-2'
            trigger='mouseenter'
          >
            <div className='day__specific-requirements'>
              <img
                src={BSLIcon}
                className='day__specific-requirements-icon'
                alt='BSL Performance'
              />
            </div>
          </Tooltip>
        </Fragment>
      )}
      {performances.find(p => p.id === 6573848) && (
        <Fragment>
          <Tooltip
            title='This performance has audio description for those that require it'
            position='bottom'
            distance='-2'
            trigger='mouseenter'
          >
            <div className='day__specific-requirements'>
              <img
                src={ADIcon}
                className='day__specific-requirements-icon'
                alt='AD Performance'
              />
            </div>
          </Tooltip>
        </Fragment>
      )}
      {showAvailability ? (
        soldOut ? (
          <div className='mobile-availability__indicator-bar mobile-availability__indicator-bar--sold-out' />
        ) : (
          <div
            className='mobile-availability__indicator-bar'
            style={{
              width: `${availabilityWidth}%`
            }}
          />
        )
      ) : null}
    </div>
  );
};

export default MobileAvailability;
