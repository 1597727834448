import { connect } from 'react-redux';
import { BasketTotal } from '../../../components/Booking/Booking';
import { basketSelectors } from '../../../modules/basket/basket';

const mapStateToProps = (state, ownProps, footerButtonColor, wolfBooking) => {
  return {
    basketTotal: basketSelectors.getBasketTotal(state),
    bookingFeeTotal: basketSelectors.getBookingFeeTotal(state),
    currency: basketSelectors.getBasketCurrency(state),
    numberOfTickets: basketSelectors.getBasketItemCount(state),
    basket: basketSelectors.getBasket(state)
  };
};

export default connect(mapStateToProps)(BasketTotal);
