import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Redirect } from 'react-router-dom';
import { bindPromiseCreators } from 'redux-saga-routines';

import BasketSummaryContainer from './BasketSummaryContainer';
import LoginContainer from './LoginContainer';
import ForgotPasswordContainer from './ForgotPasswordContainer';
import RegisterAndPayContainer from '../RegisterAndPay/RegisterAndPayContainer';

import { BookingPage } from '../../../components/Booking/Booking';
import { discountActions } from '../../../modules/discount/discount';
import {
  reservationSelectors,
  reservationActions
} from '../../../modules/reservation/reservation';
import { orderSelectors } from '../../../modules/order/order';
import { authSelectors } from '../../../modules/auth/auth';
import {
  specificRequirementsActions,
  specificRequirementsSelectors
} from '../../../modules/specificRequirements/specificRequirements';

import './ConfirmOrderContainer.css';
import { ElementsConsumer } from '@stripe/react-stripe-js';

class ConfirmOrderContainer extends Component {
  handleEditBasketClick = () => {
    const { eventSlug, performanceId, history, lng } = this.props;
    history.push(
      `/${lng ? lng + '/' : ''}event/${eventSlug}/${performanceId}/tickets`
    );
  };
  handleReservationExpired = () => {
    const { eventSlug, history, expireReservation, lng } = this.props;
    expireReservation();
    history.push(
      `/${lng ? lng + '/' : ''}event/${eventSlug}/reservation-expired`
    );
  };
  handleDiscountSubmit = values => {
    const { onDiscountSubmit, reservationId } = this.props;
    return onDiscountSubmit({
      discountCode: values.code,
      reservationId
    });
  };
  handleAddSpecificRequirement = value => {
    if (value.discount) {
      this.handleDiscountSubmit({ code: value.discount });
    }
    if (value.message) {
      this.props.addSpecificRequirementMessage({ message: value.message });
    }
  };
  handleRemoveSpecificRequirement = value => {
    // if (value.discount){
    //   this.props.createReservation()
    // }
    if (value.message) {
      this.props.removeSpecificRequirementMessage({ message: value.message });
    }
  };
  handleClearSpecificRequirements = value => {
    this.props.clearSpecificRequirementMessage();
  };
  render() {
    const {
      eventSlug,
      reservationId,
      isOrderComplete,
      lng,
      hasValidReservation,
      orderRequiresAction,
      includePassword = false
    } = this.props;
    if (isOrderComplete) {
      return (
        <Redirect to={`/${lng ? lng + '/' : ''}event/${eventSlug}/success`} />
      );
    } else if (!hasValidReservation && !orderRequiresAction) {
      return <Redirect to={`/${lng ? lng + '/' : ''}event/${eventSlug}`} />;
    }
    return (
      <ElementsConsumer>
        {({ stripe, elements }) => (
          <BookingPage
            title='Confirm and Pay'
            reservationExpiryDate={this.props.reservationExpiryDate}
            onReservationExpired={this.handleReservationExpired}
            isLoading={this.props.isCreatingOrder}
            hasFullScreenLoading={true}
            loadingMessage='Processing Your Order'
            gatsbyBooking={this.props.gatsbyBooking}
            wolfBooking={this.props.wolfBooking}
          >
            <span className='confirm-order__content'>
              <span className='confirm-order__specific-requirements'>
                {this.props.specificRequirementsMessage}
              </span>
              <span className='confirm-order__content-section confirm-order__content-section--order'>
                <BasketSummaryContainer
                  onEditBasketClick={this.handleEditBasketClick}
                  onDiscountSubmit={this.handleDiscountSubmit}
                  wolfBooking={this.props.wolfBooking}
                />
              </span>
              <span className='confirm-order__content-section confirm-order__content-section--payment'>
                {this.props.shouldShowLoginForm ? (
                  this.props.shouldShowForgotPasswordForm ? (
                    <ForgotPasswordContainer
                      wolfBooking={this.props.wolfBooking}
                    />
                  ) : (
                    <LoginContainer wolfBooking={this.props.wolfBooking} />
                  )
                ) : (
                  <Fragment>
                    <RegisterAndPayContainer
                      reservationId={reservationId}
                      includePassword={includePassword}
                      wolfBooking={this.props.wolfBooking}
                      eventSlug={eventSlug}
                      lng={this.props.lng}
                      stripe={stripe}
                    />
                    {/* <SpecificRequirements
                      shouldShowSpecificRequirements={
                        this.props.shouldShowSpecificRequirements
                      }
                      hideSpecificRequirements={
                        this.props.hideSpecificRequirements
                      }
                      showSpecificRequirements={
                        this.props.showSpecificRequirements
                      }
                      onAddSpecificRequirement={
                        this.handleAddSpecificRequirement
                      }
                      onRemoveSpecificRequirement={
                        this.handleRemoveSpecificRequirement
                      }
                      onClearSpecificRequirements={
                        this.handleClearSpecificRequirements
                      }
                      wolfBooking={this.props.wolfBooking}
                    /> */}
                  </Fragment>
                )}
              </span>
            </span>
          </BookingPage>
        )}
      </ElementsConsumer>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const { eventSlug, lng } = match.params;
  return {
    eventSlug: eventSlug,
    lng,
    reservationId: reservationSelectors.getReservationReference(state),
    hasValidReservation: reservationSelectors.getHasValidReservation(state),
    orderRequiresAction: orderSelectors.getOrderRequiresAction(state),
    isOrderComplete: orderSelectors.getIsOrderComplete(state),
    isCreatingOrder: orderSelectors.getIsCreating(state),
    performanceId: reservationSelectors.getReservationPerformanceId(state),
    reservationExpiryDate: reservationSelectors.getExpiryDate(state),
    shouldShowSpecificRequirements: specificRequirementsSelectors.getShouldShowSpecificRequirements(
      state
    ),
    specificRequirementsMessage: specificRequirementsSelectors.getSpecificRequirementsMessage(
      state
    ),
    shouldShowLoginForm: authSelectors.getShouldShowLogin(state),
    shouldShowForgotPasswordForm: authSelectors.getShouldShowForgotPassword(
      state
    )
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        onDiscountSubmit: discountActions.submitDiscountForm
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        ...reservationActions,
        ...specificRequirementsActions
      },
      dispatch
    )
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ConfirmOrderContainer);
