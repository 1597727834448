import React from 'react';
import './LineItem.css';

/* eslint-disable react/style-prop-object */
import { FormattedNumber } from 'react-intl';

const LineItem = ({
  description,
  faceValue,
  bookingFee,
  currency,
  quantity
}) => {
  const total = bookingFee ? faceValue + bookingFee : faceValue;
  return (
    <span className="line-item">
      <span className="line-item__description">
        {quantity} x {description}{' '}
        <span className="line-item__price">
          (
          <FormattedNumber
            value={faceValue}
            style="currency"
            currency={currency}
          />
          {bookingFee > 0 && (
            <span className="line-item__booking-fee">
              +{' '}
              <FormattedNumber
                value={bookingFee}
                style="currency"
                currency={currency}
              />{' '}
              fee
            </span>
          )})
        </span>
      </span>
      <span className="line-item__total">
        <FormattedNumber
          value={total * quantity}
          style="currency"
          currency={currency}
        />
      </span>
    </span>
  );
};

export default LineItem;
