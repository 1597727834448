import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import './Accordion.css';

const AccessibilityAccordion = () => {
  return (
    <Accordion className='accordion' allowZeroExpanded>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              Are there Audio Described and BSL Interpreted performances?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            There will be a BSL Interpreted performance on Thursday 17th March
            2022 at 6:00pm.{' '}
            <a
              className='faq_link'
              target='_blank'
              rel='noopener noreferrer'
              href='https://amex.immersivedoctorwho.com/event/doctor-who-time-fracture-amex/6573812/tickets'
            >
              Tickets can be booked here
            </a>
            .
          </p>
          <p>
            There will be an Audio Described performance on Thursday 31st March
            2022 at 6pm.{' '}
            <a
              className='faq_link'
              target='_blank'
              rel='noopener noreferrer'
              href='https://amex.immersivedoctorwho.com/event/doctor-who-time-fracture-amex/6573848/tickets'
            >
              Tickets can be booked here
            </a>
            .
          </p>
          <p>
            For more information on Audio Described or BSL Interpreted
            performances, you can let us know by phone, email or SMS. Our team
            can let you know when these have been scheduled. They may also be
            highlighted for you on the booking calendar so keep an eye out for
            the accessibility icons.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              Are toilets available throughout the performance?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Toilets are available at certain points throughout the performance.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              Do you offer Essential Companion tickets?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            We offer an Essential Companion ticket, for those who require them,
            at no extra cost.
          </p>
          <p>Please call Arts Tickets on 0203 053 1500 to book.</p>
          <p>
            Alternatively, you can email{' '}
            <a href='mailto:ticketing@artstickets.co.uk' className='faq_link'>
              ticketing@artstickets.co.uk
            </a>{' '}
            or text/Whatsapp 07984 414 175.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>Is your venue wheelchair accessible?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Yes, we are able to provide a wheelchair track at the 12pm or 6pm
            time slots. Patrons will be accompanied by a member of Front of
            House and a Time Lord Guide. We kindly ask wheelchair patrons to
            book tickets by calling Arts Tickets on 0203 053 1500.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              I have specific requirements, can you assist?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            If you have queries that have not been answered by the information
            on this{' '}
            <a
              href='/specific-requirements'
              className='faq_link'
              target='_blank'
              rel='noopener noreferrer'
            >
              Specific Requirements page
            </a>
            , please contact ArtsTickets for further assistance.
          </p>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default AccessibilityAccordion;
