import React from 'react';
import Countdown from 'react-countdown-now';
import './Timer.css';

const Timer = ({ reservationExpiryDate, onReservationExpired }) => {
  const renderer = renderProps => {
    const { hours, minutes, seconds } = renderProps.formatted;
    const { completed } = renderProps;
    if (completed) {
      return null;
    } else {
      return (
        <span>
          {hours !== '00' ? `${hours}:` : ''}
          {minutes}:{seconds}
        </span>
      );
    }
  };
  return reservationExpiryDate ? (
    <span className="timer-container">
      <span className="timer">
        <Countdown
          date={reservationExpiryDate}
          onComplete={onReservationExpired}
          renderer={renderer}
        />
      </span>
    </span>
  ) : null;
};

export default Timer;
