import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { bindActionCreators } from 'redux';
import { ForgotPasswordForm } from '../../../components/Booking/Booking';
import Button from '../../../components/Button/Button';
import { authActions, authSelectors } from '../../../modules/auth/auth';
import './ConfirmOrderContainer.css';

class ForgotPasswordContainer extends Component {
  handleForgotPasswordSubmit = values =>
    this.props.onSubmitForgotPassword(values);

  handleBackToLoginClick = e => {
    e.preventDefault();
    this.props.showLoginForm();
  };
  render() {
    const { isSent } = this.props;
    return (
      <div className='payment-form'>
        <span className='payment-form__header'>
          <span className='payment-form__title'>Forgot Password</span>
          <span>
            <Button onClick={this.props.hideLoginForm} className='login-link'>
              Back to Checkout as Guest
            </Button>
          </span>
        </span>
        {isSent ? (
          <div>
            <p className='forgot-password-text'>
              An email has been sent with instructions on how to reset your
              password. Once you have reset your password, please click the
              button to go back to login:
            </p>
            <p>
              <Button onClick={this.handleBackToLoginClick}>
                Back to Login
              </Button>
            </p>
          </div>
        ) : (
          <ForgotPasswordForm
            onSubmit={this.handleForgotPasswordSubmit}
            wolfBooking={this.props.wolfBooking}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return {
    isSent: authSelectors.getForgotPasswordSent(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        onSubmitForgotPassword: authActions.submitForgotPassword
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        ...authActions
      },
      dispatch
    )
  };
};
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordContainer);
