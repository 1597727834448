import React from 'react';
import { FacebookShareButton, TwitterShareButton } from 'react-share';
// import Button from "../../Button/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './OrderSuccess.css';

const DWTFShareUrl = window.location.host;
const DWTFTitle = 'I just booked tickets to Doctor Who: Time Fracture';
const DWTFHashtags = ['BeTheHero'];
const DWTFTwitterUsername = 'DWtimefracture';

const OrderSuccess = ({
  receiptUrl,
  emailAddress,
  customer,
  reference,
  wolfBooking,
  gatsbyBooking,
  className,
}) => {
  // const orderSuccessButtonClass = classNames(
  //   "button button--mobile-full download-action"
  // );

  return (
    <div>
      <div className='order-success-container'>
        <div className='order-success-header'>
          <h4>Order ref: {reference}</h4>
          {/* <Button
            href={receiptUrl}
            download
            className={orderSuccessButtonClass}
          >
            Download Tickets
          </Button> */}
        </div>
        <p>
          Thanks for your order. Confirmation has been sent to {emailAddress}.
        </p>
        {gatsbyBooking && (
          <div className='booking-social'>
            <FacebookShareButton
              url={DWTFShareUrl}
              quote={DWTFTitle}
              hashtags={DWTFHashtags}
              className='booking-social-button button--social button--share-facebook button--mobile-full'
            >
              <FontAwesomeIcon
                icon='facebook'
                fixedWidth
                className='social-icon'
              />
              Share on Facebook
            </FacebookShareButton>
            <TwitterShareButton
              url={DWTFShareUrl}
              title={DWTFTitle}
              hashtags={DWTFHashtags}
              via={DWTFTwitterUsername}
              className='booking-social-button button--social button--share-twitter button--mobile-full'
            >
              <FontAwesomeIcon
                icon='twitter'
                fixedWidth
                className='social-icon'
              />
              Share on Twitter
            </TwitterShareButton>
          </div>
        )}
      </div>
    </div>
  );
};

export default OrderSuccess;
