import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { FormattedDate, FormattedTime } from 'react-intl';
import './DateTimeBar.css';
import classNames from 'classnames';

const DateTimeBar = ({
  performanceDateTime,
  performanceId,
  onDateClick,
  onTimeClick,
  wolfBooking,
  className,
}) => {
  const handleDateClick = (e) => {
    e.preventDefault();
    onDateClick && onDateClick();
  };
  const handleTimeClick = (e) => {
    e.preventDefault();
    onTimeClick && onTimeClick();
  };
  const lozengeClass = classNames('date-time-bar__lozenge', className, {
    'date-time-bar__lozenge--wolf': wolfBooking,
  });
  const lozengeActionClass = classNames(
    'date-time-bar__lozenge-action',
    className,
    {
      'date-time-bar__lozenge-action--wolf': wolfBooking,
    }
  );
  return (
    <div className='date-time-bar'>
      <div onClick={handleDateClick} className={lozengeClass}>
        <div className='date-time-bar__lozenge-selected'>
          <div className='date-time-bar__date'>
            <FontAwesomeIcon icon='calendar' fixedWidth className='icon' />
            {performanceDateTime && (
              <FormattedDate
                value={performanceDateTime}
                year='numeric'
                month='long'
                day='2-digit'
                weekday='long'
              />
            )}
          </div>
        </div>
        <div className={lozengeActionClass}>Change</div>
      </div>
      <div onClick={handleTimeClick} className={lozengeClass}>
        <div className='date-time-bar__lozenge-selected'>
          <div className='date-time-bar__date'>
            <FontAwesomeIcon icon='clock' fixedWidth className='icon' />
            {performanceDateTime && (
              <FormattedTime value={performanceDateTime} />
            )}
          </div>
        </div>
        <div className={lozengeActionClass}>Change</div>
      </div>
    </div>
  );
};

export default DateTimeBar;
