import { connect } from 'react-redux';
import { TicketRow } from '../../../components/Booking/Booking';
import { basketSelectors } from '../../../modules/basket/basket';

const mapStateToProps = (state, ownProps) => {
  const ticketId = ownProps.ticketId;
  return {
    quantitySelected: basketSelectors.getTicketQuantity(state, ticketId)
  };
};

export default connect(mapStateToProps)(TicketRow);
