import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import './Accordion.css';

const ImmersiveAccordion = () => {
  return (
    <Accordion className='accordion' allowZeroExpanded>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>Will there be any seating?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            As the show is an immersive production, there is no formal seating.
            You’ll be standing and encouraged to move around the venue as the
            story unfolds. All tickets are General Allocation with no seat
            numbers.
          </p>
          <p>
            However, if for any reason you are unable to stand for prolonged
            periods of time, please contact{' '}
            <a href='mailto:ticketing@artstickets.co.uk' className='faq_link'>
              ArtsTickets
            </a>{' '}
            so that the necessary arrangements can be made for you. We work hard
            to ensure our performances are as accessible as possible.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              How much audience participation will there be?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            You can decide how much you’re involved in the show, be a part of
            the action or watch the story unfold in front of you. You need only
            interact as much as you are comfortable - if you prefer to observe
            from the sidelines, nobody will mind.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              How much can you tell us about the storyline?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            We wouldn’t like to spoil any of the surprises we have in store,
            that said we will tease narrative, worlds and <em>monsters</em> now
            and again across our social channels:{' '}
            <a
              href='https://twitter.com/DWtimefracture/'
              target='_blank'
              rel='noopener noreferrer'
              className='faq_link'
            >
              Twitter
            </a>
            |{' '}
            <a
              href='https://www.instagram.com/DWtimefracture/'
              target='_blank'
              rel='noopener noreferrer'
              className='faq_link'
            >
              Instagram
            </a>{' '}
            |{' '}
            <a
              href='https://www.facebook.com/DWtimefracture/'
              target='_blank'
              rel='noopener noreferrer'
              className='faq_link'
            >
              Facebook
            </a>
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      {/* <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>Do I need to be a Doctor Who expert?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>Answer</p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>Can I attend more than once?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>Answer</p>
        </AccordionItemPanel>
      </AccordionItem> */}

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              How many worlds will there be to experience?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>In total, there’ll be 17.</p>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default ImmersiveAccordion;
