import React from 'react';
import './FormField.css';
import classNames from 'classnames';

const FormField = ({
  input,
  label,
  type,
  placeholder,
  className,
  id,
  inline,
  labelAfter,
  width,
  rows,
  hideLabel,
  narrowRow,
  meta: { touched, error, warning }
}) => {
  const style = () => {
    if (width) {
      return {
        width: `${width}%`
      };
    }
    return {};
  };

  const rowContainerClass = classNames('form__row-container', className, {
    'form__row-container--narrow': narrowRow
  });

  const rowClass = classNames(className, {
    'form__row--check': type === 'checkbox' || type === 'radio',
    form__row: type !== 'checkbox' && type !== 'radio'
  });
  const inputClass = classNames(className, {
    'form__field-input--check': type === 'checkbox' || type === 'radio',
    'form__field-input': type !== 'checkbox' && type !== 'radio',
    'form__field-input--error': (touched && error) || (touched && warning)
  });
  const labelClass = classNames('form__label', {
    'form__label--check': type === 'checkbox' || type === 'radio',
    'form__label--hidden': hideLabel
  });

  const Element = type === 'textarea' ? 'textarea' : 'input';

  return (
    <div className={rowContainerClass}>
      <div className={rowClass} style={style()}>
        {!labelAfter && (
          <label className={labelClass} htmlFor={id}>
            {label}
          </label>
        )}
        <Element
          {...input}
          placeholder={placeholder}
          id={id}
          type={type}
          className={inputClass}
          rows={rows}
        />
        {labelAfter && (
          <label className={labelClass} htmlFor={id}>
            {labelAfter}
          </label>
        )}
        {touched &&
          ((error && <div className="form__error">{error}</div>) ||
            (warning && <div className="form__error">{warning}</div>))}
      </div>
    </div>
  );
};

export default FormField;
