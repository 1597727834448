import React from 'react';
import LoadingIndicator from '../../LoadingIndicator/LoadingIndicator';
import Timer from '../Timer/Timer';
import classNames from 'classnames';
import './BookingPage.css';

const BookingPage = ({
  isLoading,
  hasFullScreenLoading,
  loadingMessage,
  title,
  children,
  reservationExpiryDate,
  onReservationExpired,
  gatsbyBooking,
  wolfBooking,
  className
}) => {
  const bookingContainerClass = classNames('booking-container', className, {
    'booking-container--wolf': wolfBooking
  });
  const bookingContainerTitleClass = classNames(
    'booking-container__title',
    className,
    {
      'booking-container__title--gatsby': gatsbyBooking,
      'booking-container__title--wolf': wolfBooking
    }
  );
  return (
    <div className={bookingContainerClass}>
      <span className='booking-container__header'>
        <span className='booking-container__left' />
        <span className={bookingContainerTitleClass}>{title}</span>
        <span className='booking-container__countdown'>
          {reservationExpiryDate && (
            <Timer
              reservationExpiryDate={reservationExpiryDate}
              onReservationExpired={onReservationExpired}
            />
          )}
        </span>
      </span>
      <span>{children}</span>
      {isLoading && (
        <LoadingIndicator
          loadingMessage={loadingMessage}
          isFullScreen={hasFullScreenLoading}
        />
      )}
    </div>
  );
};

export default BookingPage;
