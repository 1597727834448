import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import './Accordion.css';

const BookingAccordion = () => {
  return (
    <Accordion className='accordion' allowZeroExpanded>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>What is a Preview?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            A Preview is a performance very early on in the life of a
            production. All major work preparing the production has been
            completed, however there may still be ongoing work on the set or
            performance itself. This won’t at all affect your experience, you
            may just see a slightly different version of the production to the
            one reviewed and marketed once the production is officially open. To
            reflect this, pricing for Previews includes a discount on full
            ticket prices.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>What's your refund policy?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Where an event is cancelled a full refund or exchange will be issued
            (at the choice of the customer) however please note that ArtsTickets
            are not liable to reimburse customers the cost of any related
            travel, accommodation or other charges incurred around your visit.
          </p>

          <p>
            Any requested refunds or exchanges, where an event has not been
            cancelled, are processed at the discretion of ArtsTickets once the
            circumstances surrounding the request have been fully assessed.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>What's your exchange policy?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Any changes or amendments to a booking must be completed 48 hours
            prior to the performance originally booked. It is not possible to
            exchange or refund tickets for a performance that has passed (a
            performance is considered ‘passed’ once the start time on the date
            of the booking has been reached).
          </p>

          <p>
            Where a voucher refund has been issued, once the voucher code has
            been redeemed, tickets can be exchanged but not refunded.
          </p>

          <p>
            There may be an admin fee payable by the customer for changes made
            to tickets.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              How many tickets can I purchase in one transaction?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            As many as are available, though we anticipate we will need to
            restrict this in times of high demand. Based on the latest
            guidelines, tickets should be purchased only for you and your social
            bubble.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              What are the terms and conditions for my ticket?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p></p>
          <p>
            For tickets purchased directly with ArtsTickets through this
            website, you can find full Terms and Conditions{' '}
            <a href='/terms' className='faq_link'>
              here
            </a>
            . If you have any additional queries, please contact{' '}
            <a href='mailto:ticketing@artstickets.co.uk' className='faq_link'>
              ArtsTickets
            </a>
            .
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              I need to contact ArtsTickets, but I'd prefer to speak over the
              phone. Is this possible?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>It is indeed, ArtsTickets are available on 0203 053 1500.</p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>Do you offer any group discounts?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>We do indeed! See the following for our group rates.</p>

          <p>
            <b>Standard Rate</b>
            <br />
            6+ £45
            <br />
            12+ £40
            <br />
            20+ £35
            <br />
            40+ £29.50
          </p>

          <p>
            To take advantage of our early bird group rate, place your order
            before 5th September 2021.
          </p>

          <p>
            <b>Early bird rate</b>
            <br />
            6+ £40
            <br />
            12+ £35
            <br />
            20+ £29.50
            <br />
            40+ £25
          </p>

          <p>Valid on Tue-Fri performances & Sun evening.</p>

          <p>
            <b>Schools Rate (Tue-Thu)</b>
            <br />
            10+ £25 (1 free ticket per 10)
          </p>

          <p>
            Prices are per person, based on group size, valid on General
            Admission tickets only.
          </p>
          <p>
            To book your group tickets, please email{' '}
            <a href='mailto:ticketing@artstickets.co.uk' className='faq_link'>
              ArtsTickets
            </a>
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>Do you offer a family ticket?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>Not at present, but please check back for updates.</p>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default BookingAccordion;
