import React from 'react';
import classNames from 'classnames';
import './ContentCard.css';
export {
  default as ContentCardImage
} from './ContentCardImage/ContentCardImage';
export {
  default as ContentCardHeadline
} from './ContentCardHeadline/ContentCardHeadline';
export { default as ContentCardBody } from './ContentCardBody/ContentCardBody';

const ContentCard = ({ children, className, wholeline }) => {
  const ContentCardClass = classNames('content-card', className, {
    'content-card--whole-line': wholeline
  });
  return <div className={ContentCardClass}>{children}</div>;
};

export default ContentCard;
