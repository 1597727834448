import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { bindActionCreators } from 'redux';
import Button from '../../../components/Button/Button';
import { LoginForm } from '../../../components/Booking/Booking';
import { authActions } from '../../../modules/auth/auth';
class LoginContainer extends Component {
  handleLoginSubmitSuccess = () => {};
  handleForgotPasswordClick = () => {};
  handleBackToOrderClick = () => {
    this.props.hideLoginForm();
  };
  render() {
    return (
      <div className='payment-form'>
        <span className='payment-form__header'>
          <span className='payment-form__title'>Login Details</span>
          <span>
            <Button
              onClick={this.handleBackToOrderClick}
              className='login-link'
            >
              Back to Checkout as Guest
            </Button>
          </span>
        </span>
        <LoginForm
          onSubmit={authActions.submitLoginForm}
          onForgotPasswordClick={this.handleForgotPasswordClick}
          wolfBooking={this.props.wolfBooking}
        />
        <span>
          <Button
            onClick={this.props.showForgotPasswordForm}
            className='login-link'
          >
            Forgot Password?
          </Button>
        </span>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        submitLoginForm: authActions.submitLoginForm
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        ...authActions
      },
      dispatch
    )
  };
};

export default connect(null, mapDispatchToProps)(LoginContainer);
