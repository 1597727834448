import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import './Accordion.css';

const CovidAccordion = () => {
  return (
    <Accordion className='accordion' allowZeroExpanded>
      {/* <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              Changes to Doctor Who: Time Fracture from the 19th July
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Doctor Who : Time Fracture is pleased to announce it will be making
            some slight changes to the restrictions in place, in line with the
            new Government guidance.
          </p>
          <p>
            To remain cautious, we will only be increasing our capacity
            slightly, from 90 people (45% of full capacity) to 100 people (50%
            of full capacity) pending further review in August.
          </p>

          <p>Tickets can be bought as usual from our website.</p>

          <p>
            It will now be possible to consume drinks while standing, however we
            ask for masks to continue to be worn around the venue at all times
            when not drinking to keep our audience and company safe. Your
            cooperation will be greatly appreciated in this matter.
          </p>

          <p>
            We will continue with additional ventilation measures and rigorous
            cleaning of touch points, as well as continuing to provide hand
            sanitisation points. Our staff are working very hard to help
            everyone and we appreciate your continued support.
          </p>
          <p>
            We will of course continue to monitor how things are going over the
            next few weeks and make any future decisions based upon Government
            advice.
          </p>
        </AccordionItemPanel>
      </AccordionItem> */}

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              What happens if I show symptoms of covid-19?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Based on current guidelines our host venue Immersive | LDN have
            implemented a NO-QUESTIONS-ASKED EXCHANGE POLICY. Any ticket
            purchased for an event at Immersive | LDN will be exchangeable for
            an equivalent ticket on an alternative date up until the
            commencement time of the booked performance. This will be arranged
            in person, or via email. This NO-QUESTIONS-ASKED EXCHANGE POLICY is
            under constant review and Immersive | LDN reserves the right to
            cancel or amend this policy with immediate effect and at any time.
            This policy applies to tickets booked at{' '}
            <a href='https://artstickets.co.uk'>artstickets.co.uk</a>,
            <a href='https://immersiveldn.com'>immersiveldn.com</a> and{' '}
            <a href='https://immersivedoctorwho.com'>immersivedoctorwho.com</a>.
            We cannot guarantee that third party ticket agents will follow the
            same policy although they will be encouraged to do so. This policy
            will be reviewed and amended as Government guidelines develop.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      {/* <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              What is the cleaning policy at the venue?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            All actors will be fully briefed based on the latest guidelines to
            ensure social distancing between themselves and the audience at all
            times.
          </p>
        </AccordionItemPanel>
      </AccordionItem> */}

      {/* <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              How will social distancing be managed?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Capacity has been reduced in line with the latest guidelines and to
            allow social distancing. Audience members will be expected to stay
            within their booking bubble, and in relevant areas the floor will be
            marked to help guide the audience.
          </p>
        </AccordionItemPanel>
      </AccordionItem> */}

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              Will the actors adhere to social distancing?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            All actors will be fully briefed based on the latest guidelines to
            ensure social distancing between themselves and the audience at all
            times.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              Will you take my temperature on arrival?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            We will take temperature readings from all patrons on arrival at the
            venue.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>Do I have to wear a mask?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            As an immersive show, and with our audience frequently in close
            proximity with our performers, we respectfully ask the following of
            our patrons:
          </p>
          <p className='faq-textleft'>
            <ul>
              <li>
                Wear a mask at all times when inside the building, unless you
                are exempt.
              </li>
              <li>
                Whilst it is possible to remove your mask to eat and drink, we
                would ask that you remain respectful of the performers and keep
                your mask on when you are in close proximity to them.
              </li>
              <li>
                This may also include any side rooms or smaller performance
                spaces that you may be taken into, and we would ask that no
                drinks are consumed in these smaller spaces with masks remaining
                on at all times
              </li>
            </ul>
          </p>

          <p>
            This is to keep everyone, including our staff, our performers, and
            our patrons safe and well at this time.
          </p>
          <p>We thank you for your full co-operation.</p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              Will I need proof of vaccination status?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            As an immersive show, and with our audience frequently in close
            proximity with our performers, we respectfully ask our patrons to
            provide one of the following:
          </p>
          <p className='faq-textleft'>
            <ul>
              <li>
                Proof of a negative Lateral Flow Test taken within the previous
                48 hours
              </li>
              <li>
                Proof of double vaccination via the NHS App or vaccination cards
              </li>
              <li>
                Proof of natural immunity to COVID 19 within the last 180 days
              </li>
              <li>
                Proof of medical exemption or you are taking part in a clinical
                trial
              </li>
            </ul>
          </p>
          <p>
            Our security staff will be checking these, prior to entry into the
            building, so we would ask that all members of your party have them
            to hand as you approach the entrance for the sake of speed.
          </p>

          <p>The easiest way is via the NHS App.</p>

          <p>
            Unfortunately,{' '}
            <b>
              <u>no exceptions</u>
            </b>{' '}
            can be made to this rule for patrons aged 12 and over. This is in
            place to protect our staff, our performers and our audience members.
          </p>

          <p>
            If you are unable to provide proof then you will be denied entry to
            the venue.
          </p>

          <p>Thank you for your co-operation.</p>
        </AccordionItemPanel>
      </AccordionItem>

      {/* <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              Will I be grouped with people outside of my bubble?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            No, based on the latest guidelines, you will not be grouped with
            people outside of your bubble.{' '}
          </p>
        </AccordionItemPanel>
      </AccordionItem> */}

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              Will there be hand sanitiser stations at the venue?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Hand santiser stations will be positioned at the entrance, and at
            all bars and toilets.
          </p>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default CovidAccordion;
