import { connect } from 'react-redux';
import { BasketSummary } from '../../../components/Booking/Booking';
import { reservationSelectors } from '../../../modules/reservation/reservation';
import {
  discountSelectors,
  discountActions
} from '../../../modules/discount/discount';

const mapStateToProps = (state, ownProps) => {
  return {
    faceValueTotal: reservationSelectors.getFaceValueTotal(state),
    bookingFeeTotal: reservationSelectors.getBookingFeeTotal(state),
    basketTotal: reservationSelectors.getTotalPayable(state),
    totalDiscount: reservationSelectors.getDiscountTotal(state),
    currentDiscountCode: discountSelectors.getCurrentDiscountCode(state),
    currency: reservationSelectors.getCurrency(state),
    ticketItems: reservationSelectors.getTickets(state),
    productItems: reservationSelectors.getProducts(state),
    shouldShowDiscountForm: discountSelectors.getShouldShowDiscountForm(state)
  };
};

export default connect(mapStateToProps, {
  onHideDiscountForm: discountActions.hideDiscountForm,
  onShowDiscountForm: discountActions.showDiscountForm
})(BasketSummary);
