import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import BookingAccordion from './BookingAccordion';
import './Accordion.css';
import { getAccordionPreExpanded } from './getAccordionPreExpanded';

const BookingAccordionHeader = () => {
  const preExpanded = getAccordionPreExpanded('booking');

  return (
    <div id='booking'>
      <Accordion
        className='accordion'
        allowZeroExpanded
        preExpanded={preExpanded}
      >
        <AccordionItem uuid='booking'>
          <AccordionItemHeading>
            <AccordionItemButton>
              <h3 className='gatsby-h3'>Booking</h3>
              <div className='accordion__arrow' />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <BookingAccordion />
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default BookingAccordionHeader;
