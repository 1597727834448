import React from 'react';
import Portal from './Portal';
import './FooterBar.css';
const FooterBar = ({
  children,
  footerBackgroundColor,
  footerBorderColor,
  footerLinkColor,
  footerFontFamily
}) => {
  const styles = {
    backgroundColor: `${footerBackgroundColor}`,
    borderTop: `0.2px solid ${footerBorderColor}`,
    color: `${footerLinkColor}`,
    fontFamily: `${footerFontFamily}`
  };
  return (
    <Portal>
      <div className="footer-bar" style={styles}>
        {children}
      </div>
    </Portal>
  );
};

export default FooterBar;
