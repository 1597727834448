import React, { Component, Fragment } from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { BookingPage, TicketTable } from '../../../components/Booking/Booking';
import FooterBar from '../../../components/FooterBar/FooterBar';
import { DateTimeBar } from '../../../components/Booking/Booking';
import TicketRowContainer from './TicketRowContainer';

import {
  performanceActions,
  performanceSelectors
} from '../../../modules/performances/performances';

import {
  ticketActions,
  ticketSelectors
} from '../../../modules/tickets/tickets';

import { productActions } from '../../../modules/products/products';

import { basketActions, basketSelectors } from '../../../modules/basket/basket';
import {
  reservationActions,
  reservationSelectors
} from '../../../modules/reservation/reservation';

import BasketTotalContainer from '../Footer/BasketTotalContainer';

class TicketTableContainer extends Component {
  componentDidMount() {
    this.props.fetchPerformance({ performanceId: this.props.performanceId });
    this.props.loadTickets(this.props.performanceId);
    this.props.fetchProducts({ performanceId: this.props.performanceId });
  }
  handleAddToBasketClick = (ticketId, quantity) => {
    this.props.addTicketToBasket(ticketId, quantity);
  };
  handleRemoveFromBasketClick = (ticketId, quantity) => {
    this.props.removeTicketFromBasket(ticketId, quantity);
  };
  handleBookNowClick = () => {
    const {
      sessionId,
      basket,
      lng,
      eventSlug,
      performanceId,
      createReservation
    } = this.props;
    const data = {
      ...(sessionId && { sessionId: sessionId }),
      ...basket
    };
    createReservation({
      body: data,
      successUrl: `/${
        lng ? lng + '/' : ''
      }event/${eventSlug}/${performanceId}/products`
    });
  };
  handleDateClick = () => {
    const { history, eventSlug, lng } = this.props;
    history.push(`/${lng ? lng + '/' : ''}event/${eventSlug}`);
  };
  handleTimeClick = () => {
    const { lng, eventSlug, performanceDateTime, history } = this.props;
    const dayString = moment(performanceDateTime).format('YYYY-MM-DD');
    const url = `/${
      lng ? lng + '/' : ''
    }event/${eventSlug}/${dayString}/select-time`;
    history.push(url);
  };
  render() {
    return (
      <Fragment>
        <BookingPage
          isLoading={this.props.ticketsLoading}
          title="Select your tickets"
          gatsbyBooking={this.props.gatsbyBooking}
          wolfBooking={this.props.wolfBooking}
        >
          <DateTimeBar
            performanceDateTime={this.props.performanceDateTime}
            onTimeClick={this.handleTimeClick}
            onDateClick={this.handleDateClick}
            wolfBooking={this.props.wolfBooking}
          />
          <TicketTable
            tickets={this.props.tickets}
            performanceId={this.props.performanceId}
            wolfBooking={this.props.wolfBooking}
            renderTicketRow={ticket => (
              <TicketRowContainer
                key={ticket.id}
                name={ticket.description}
                detail={ticket.detail}
                faceValue={ticket.total}
                bookingFee={ticket.feeTotal}
                currency={ticket.currency}
                ticketId={ticket.id}
                minimumQuantity={this.props.minimumQuantity}
                quantityAvailable={ticket.inventoryLeft}
                onAddToBasketClick={this.handleAddToBasketClick}
                onRemoveFromBasketClick={this.handleRemoveFromBasketClick}
                wolfBooking={this.props.wolfBooking}
              />
            )}
          />
        </BookingPage>

        {this.props.numberOfItems > 0 && (
          <FooterBar
            footerFontFamily={this.props.footerFontFamily}
            footerButtonColor={this.props.footerButtonColor}
            footerBackgroundColor={this.props.footerBackgroundColor}
            wolfBooking={this.props.wolfBooking}
          >
            <BasketTotalContainer
              onBookNowClick={this.handleBookNowClick}
              isCreatingReservation={this.props.isCreatingReservation}
              buttonText={'Continue'}
              footerButtonColor={this.props.footerButtonColor}
              wolfBooking={this.props.wolfBooking}
            />
          </FooterBar>
        )}
      </Fragment>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const performanceId = parseInt(match.params.performanceId, 10);
  const { eventSlug, lng } = match.params;
  return {
    performanceId,
    eventSlug,
    lng,
    performanceDateTime: performanceSelectors.getPerformanceStartDate(
      state,
      performanceId
    ),
    tickets: ticketSelectors.getTickets(state, performanceId),
    ticketsLoading: ticketSelectors.getLoading(state, performanceId),
    basket: basketSelectors.getBasket(state),
    numberOfItems: basketSelectors.getBasketItemCount(state),
    isCreatingReservation: reservationSelectors.getIsCreating(state),
    sessionId: reservationSelectors.getSessionId(state)
  };
};

export default connect(
  mapStateToProps,
  {
    ...performanceActions,
    ...ticketActions,
    ...productActions,
    ...basketActions,
    ...reservationActions
  }
)(TicketTableContainer);
