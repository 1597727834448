import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { bindPromiseCreators } from 'redux-saga-routines';
import { PaymentForm } from '../../../components/Booking/Booking';
import Button from '../../../components/Button/Button';
import { orderActions, orderSelectors } from '../../../modules/order/order';
import { authActions, authSelectors } from '../../../modules/auth/auth';
import { reservationSelectors } from '../../../modules/reservation/reservation';
import './RegisterAndPayContainer.css';
import { SubmissionError } from 'redux-form';

class RegisterAndPayContainer extends Component {
  handleConfirmAndPayClick = (paymentMethod, values) => {
    return this.props
      .onRegistrationFormSubmit({
        ...values
      })
      .then(r => this.handleSubmitCardForm(paymentMethod));
  };
  handleSubmitCardForm = paymentMethod =>
    // We must return a promise that rejects with a SubmissionError for redux form.
    // Returning a whole new Promise here because we need to handle multiple reject cases:
    // - The first onPaymentFormSubmit fails. This returns a SubmissionError via the saga flow
    // - The second onPaymentFormSubmit fails (3DS). This returns a SubmissionError here
    // - At either the first or second stage, the line up API fails for some reason
    // - The first onPaymentFormSubmit succeeds but we still have an orderId yet no client secret (this should never happen)

    // We don't do any resolves with this promise, as that's handled as the saga level
    new Promise((_resolve, reject) => {
      this.props
        .onPaymentFormSubmit({
          ...(paymentMethod.id && { paymentMethod: paymentMethod.id }),
          reservationId: this.props.reservationId,
          ...(this.props.affiliate && { affiliate: this.props.affiliate })
        })
        .then(({ response }) => {
          // If we still have an orderId, it means we probably need to do
          // the next onPaymentFormSubmit (3DS)
          if (this.props.orderId) {
            const clientSecret =
              response.entities.order[this.props.orderId]
                .paymentIntentClientSecret;

            if (clientSecret) {
              this.props.stripe
                .handleCardAction(clientSecret)
                .then(({ paymentIntent, error }) => {
                  if (paymentIntent) {
                    this.props
                      .onPaymentFormContinue({
                        paymentIntentId: paymentIntent.id,
                        id: this.props.orderId
                      })
                      .then(() => {
                        this.props.onPaymentComplete();
                      })
                      .catch(error => {
                        reject(error);
                      });
                  } else if (error) {
                    this.props.onPaymentFormFail();
                    reject(
                      new SubmissionError({
                        _error: error.message
                      })
                    );
                  }
                });
            } else {
              // Possible case where for some reason we still have an order ID but no clientSecret
              console.error('No client secret returned');
              this.props.onPaymentFormFail();
              reject(
                new SubmissionError({
                  _error: 'There was a problem processing your order.'
                })
              );
            }
          } else {
            // If no client secret, just complete
            this.props.onPaymentComplete();
          }
        })
        .catch(error => {
          reject(error);
        });
    });

  handleLoginClick = e => {
    e.preventDefault();
    this.props.showLoginForm();
  };
  handleLogoutClick = e => {
    e.preventDefault();
    this.props.logOut();
  };

  render() {
    return (
      <div className='payment-form'>
        <span className='payment-form__header'>
          <span className='payment-form__title'>Payment Details</span>
          {this.props.includePassword && this.props.isLoggedIn ? (
            <span>
              <span className='login-text'>{`Logged in as ${this.props.displayName},`}</span>
              <Button onClick={this.handleLogoutClick} className='login-link'>
                Logout
              </Button>
            </span>
          ) : (
            this.props.includePassword && (
              <span>
                <span className='login-text'>Gallifreyan Coin holder?</span>
                <Button onClick={this.handleLoginClick} className='login-link'>
                  Login
                </Button>
              </span>
            )
          )}
        </span>
        <PaymentForm
          showDetails={!this.props.isLoggedIn}
          optInText={
            this.props.isLoggedIn
              ? null
              : 'Yes, I want to receive updates from Doctor Who: Time Fracture and Immersive Everywhere in the future, regarding this show. My data should not be shared with any other third parties.'
          }
          onPaymentMethodSuccess={
            this.props.isLoggedIn
              ? this.handleSubmitCardForm
              : this.handleConfirmAndPayClick
          }
          isCreatingOrder={this.props.isCreatingOrder}
          wolfBooking={this.props.wolfBooking}
          includePassword={this.props.includePassword}
          total={this.props.basketTotal}
        />
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    ...bindPromiseCreators(
      {
        onPaymentFormSubmit: orderActions.submitPaymentForm,
        onPaymentFormContinue: orderActions.continuePaymentForm,
        onRegistrationFormSubmit: authActions.submitRegisterForm
      },
      dispatch
    ),
    ...bindActionCreators(
      {
        ...authActions,
        onPaymentFormFail: orderActions.clearOrder,
        onPaymentComplete: orderActions.completeOrder
      },
      dispatch
    )
  };
};

const mapStateToProps = state => {
  return {
    basketTotal: reservationSelectors.getStripeTotal(state),
    currency: reservationSelectors.getCurrency(state),
    isLoggedIn: authSelectors.isLoggedIn(state),
    displayName: authSelectors.getDisplayname(state),
    affiliate: authSelectors.getAffiliate(state),
    paymentIntentClientSecret: orderSelectors.getOrderPaymentIntentClientSecret(
      state
    ),
    orderId: orderSelectors.getOrderId(state)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterAndPayContainer);
