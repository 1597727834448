import moment from 'moment';
import { entitySelectors } from '../entities/entities';
//- Selectors

const getStartDate = performance =>
  performance.startTime
    ? moment(
        `${performance.startDate} ${performance.startTime}`,
        'YYYY-MM-DD HH:mm:ss'
      )
    : moment(`${performance.startDate}`, 'YYYY-MM-DD');
const getEndDate = performance =>
  performance.endTime
    ? moment(
        `${performance.endDate} ${performance.endTime}`,
        'YYYY-MM-DD HH:mm:ss'
      )
    : moment(`${performance.endDate}`, 'YYYY-MM-DD');
const getDay = performance =>
  moment(`${performance.startDate} 12:00:00`, 'YYYY-MM-DD HH:mm:ss');

export const getPerformance = (state, performanceId) =>
  entitySelectors.getEntity(state.entities.performance, performanceId);

export const getPerformances = state =>
  state.performances.byId.map(id => getPerformance(state, id));

export const getLoading = state => state.performances.loading;

export const getPerformanceStartDate = (state, performanceId) =>
  getStartDate(getPerformance(state, performanceId)).toDate();

export const getPerformancesWithDates = state =>
  getPerformances(state)
    .reduce(
      (dateArray, performance) => [
        ...dateArray,
        {
          id: performance.id,
          startDate: getStartDate(performance),
          endDate: getEndDate(performance),
          availability: performance.availability,
          performanceDay: getDay(performance),
          minTicketPrice: performance.minTicketPrice
        }
      ],
      []
    )
    .sort((p1, p2) => p1.startDate < p2.startDate);

export const getPerformancesWithDatesForDay = (state, date) =>
  getPerformancesWithDates(state)
    .filter(performance => moment(date).isSame(performance.startDate, 'day'))
    .sort((b, a) => b.startDate.unix() - a.startDate.unix());
export const getPerformancesGroupedByDay = state =>
  getPerformancesWithDates(state).reduce(
    (groupedPerfs, performance) => ({
      ...groupedPerfs,
      [performance.performanceDay.valueOf()]: [
        ...(groupedPerfs[performance.performanceDay.valueOf()] || []),
        performance
      ]
    }),
    {}
  );

export const getPerformanceDatesForTicker = state =>
  getPerformances(state)
    .slice(0, 50)
    .map(performance => getStartDate(performance).format('DD MMM'));
