import React from 'react';
import classNames from 'classnames';
import './Icon.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const Icon = ({
  className,
  name,
  right,
  small,
  large,
  nomargin,
  verticalanimated,
  color,
}) => {
  const iconStyle = {
    color: `${color}`,
  };
  const IconClass = classNames('icon', className, {
    'icon--right': right,
    'icon--small': small,
    'icon--large': large,
    'icon--no-margin': nomargin,
    'icon--vertical-animated': verticalanimated,
  });
  return (
    <FontAwesomeIcon
      icon={name}
      fixedWidth
      className={IconClass}
      style={iconStyle}
    />
  );
};

export default Icon;
