import React from 'react';
import DayPicker from 'react-day-picker';
import Day from './Day';
import Weekday from './Weekday';

import './Calendar.css';

const Calendar = ({
  performancesByDay,
  onPerformanceClick,
  onDayClick,
  wolfBooking
}) => {
  const dates = Object.keys(performancesByDay).map(date => parseInt(date, 10));
  if (!dates.length) {
    return null;
  }
  const firstDate = new Date(Math.min(...dates));
  const lastDate = new Date(Math.max(...dates));
  const handleDayClick = (day, modifiers = {}) => {
    if (modifiers.disabled) {
      return;
    }
    const dayPerformances = performancesByDay[day.getTime()];
    if (dayPerformances.length === 1) {
      const performance = dayPerformances[0];
      onPerformanceClick && onPerformanceClick(performance.id);
    } else {
      onDayClick && onDayClick(day);
    }
  };
  const noPerformance = day =>
    performancesByDay[day.getTime()] ? false : true;

  return (
    <DayPicker
      onDayClick={handleDayClick}
      renderDay={day => (
        <Day
          day={day}
          performancesByDay={performancesByDay}
          onPerformanceClick={onPerformanceClick}
          wolfBooking={wolfBooking}
        />
      )}
      weekdayElement={Weekday}
      disabledDays={[{ before: new Date() }, noPerformance]}
      initialMonth={firstDate}
      fromMonth={firstDate}
      toMonth={lastDate}
      showOutsideDays={true}
      wolfBooking={wolfBooking}
      firstDayOfWeek={1}
    />
  );
};

export default Calendar;
