import React from 'react';
import './TicketTable.css';
import BSLIcon from '../../../images/bsl_icon.png';
import ADIcon from '../../../images/ad_icon.png';
import PartyIcon from '../../../images/party_icon.png';
import WheelchairIcon from '../../../images/wheelchair_icon.png';

const TicketTable = ({
  tickets,
  performanceId,
  renderTicketRow,
  wolfBooking
}) => {
  return (
    <div className='ticket-table-container'>
      <div className='ticket-table'>
        <span className='ticket-table-mobile-header-container'>
          {performanceId === 456070 && (
            <span className='ticket-table__specific-requirements'>
              <img
                src={WheelchairIcon}
                className='ticket-table__specific-requirements-icon'
                alt='Wheelchair'
                title='Wheelchair'
              />
              Wheelchair accessible - This performance is accessible for
              customers who require a wheelchair
            </span>
          )}
          {performanceId === 6573812 && (
            <span className='ticket-table__specific-requirements'>
              <img
                src={BSLIcon}
                className='ticket-table__specific-requirements-icon'
                alt='BSL'
                title='BSL Performance'
              />
              British Sign Language Performance - This performance will
              incorporate BSL interpreters for those who require them.
            </span>
          )}
          {performanceId === 6573848 && (
            <span className='ticket-table__specific-requirements'>
              <img
                src={ADIcon}
                className='ticket-table__specific-requirements-icon'
                alt='AD'
                title='AD Performance'
              />
              Audio Described Performance - This performance has audio
              description for those that require it.
            </span>
          )}
          {performanceId === 6329626 && (
            <span className='ticket-table__specific-requirements'>
              <img
                src={PartyIcon}
                className='ticket-table__specific-requirements-icon'
                alt='AD'
                title='AD Performance'
              />
              Attend the NYE performance (show begins 7.30pm), After Party
              (entrance from 10pm, 18+, ID will be required for entry) or both{' '}
              <a href='#nye' className='additional-info-link'>
                Read More
              </a>
            </span>
          )}
          <h3 className='ticket-table-mobile-header'>
            Select ticket to add to basket
          </h3>
        </span>
        <span className='ticket-table-table'>
          {tickets.map(renderTicketRow)}
        </span>
      </div>
    </div>
  );
};

export default TicketTable;
