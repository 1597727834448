import React from 'react';
import classNames from 'classnames';
import './MapAddressOverlay.css';

const MapAddressOverlay = ({ children, className, gatsby, event, wolf }) => {
  const MapAddressOverlayClass = classNames('map-address-overlay', className, {
    'map-address-overlay--gatsby': gatsby,
    'map-address-overlay--event': event,
    'map-address-overlay--wolf': wolf
  });

  return <div className={MapAddressOverlayClass}>{children}</div>;
};

export default MapAddressOverlay;
