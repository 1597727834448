import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {
  BookingPage,
  OrderSuccess,
  FooterMenu
} from '../../../components/Booking/Booking';
import FooterBar from '../../../components/FooterBar/FooterBar';
import { orderSelectors } from '../../../modules/order/order';
import { authSelectors } from '../../../modules/auth/auth';

const SuccessContainer = ({
  eventSlug,
  lng,
  order,
  footerLinks,
  gatsbyBooking,
  wolfBooking,
  customer,
  footerBackgroundColor,
  footerBorderColor,
  footerLinkColor,
  footerFontFamily,
  footerButtonColor
}) => {
  if (!order) {
    return <Redirect to={`/${lng ? lng + '/' : ''}event/${eventSlug}`} />;
  }
  if (order) {
    window.order = order;
  }
  return (
    <BookingPage
      title='Order Success'
      gatsbyBooking={gatsbyBooking}
      wolfBooking={wolfBooking}
    >
      <OrderSuccess
        receiptUrl={order.receiptUrl}
        emailAddress={order.customer.email}
        reference={order.reference}
        wolfBooking={wolfBooking}
        gatsbyBooking={gatsbyBooking}
        customer={customer}
      />
      <FooterBar
        footerBackgroundColor={footerBackgroundColor}
        footerBorderColor={footerBorderColor}
        footerLinkColor={footerLinkColor}
        footerFontFamily={footerFontFamily}
        footerButtonColor={footerButtonColor}
      >
        <FooterMenu showBook={false} links={footerLinks} lng={lng} />
      </FooterBar>
    </BookingPage>
  );
};

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const { eventSlug, lng } = match.params;
  return {
    eventSlug,
    lng,
    order: orderSelectors.getOrder(state),
    customer: authSelectors.getCustomer(state)
  };
};

export default connect(mapStateToProps, {})(SuccessContainer);
