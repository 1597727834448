import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { BookingPage } from '../../../components/Booking/Booking';

const ReservationExpiredContainer = ({ eventSlug, lng }) => (
  <BookingPage title='Your time ran out'>
    <div className='booking-container__body'>
      <p>Your reservation expired.</p>
    </div>
    <Link to={`/`} className='button button--wolf button--margin-top'>
      Back to Tickets
    </Link>
  </BookingPage>
);

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const { eventSlug, lng } = match;
  return {
    eventSlug,
    lng
  };
};

export default connect(mapStateToProps)(ReservationExpiredContainer);
