import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  performanceActions,
  performanceSelectors
} from '../../../modules/performances/performances';
import { BookingPage, SelectTime } from '../../../components/Booking/Booking';

class SelectTimeContainer extends Component {
  componentDidMount() {
    if (!this.props.performances || this.props.performances.length === 0) {
      this.props.fetchPerformances(this.props.eventSlug);
    }
  }
  handPerformanceClick = performanceId => {
    const { eventSlug, lng, history } = this.props;
    history.push(
      `/${lng ? lng + '/' : ''}event/${eventSlug}/${performanceId}/tickets`
    );
  };
  handleDateClick = () => {
    const { eventSlug, lng, history } = this.props;
    history.push(`/${lng ? lng + '/' : ''}event/${eventSlug}`);
  };

  render() {
    return (
      <BookingPage
        title='Select a time'
        isLoading={this.props.performancesLoading}
        gatsbyBooking={this.props.gatsbyBooking}
        wolfBooking={this.props.wolfBooking}
      >
        <SelectTime
          performances={this.props.performances}
          onSelectTime={this.handPerformanceClick}
          onDateClick={this.handleDateClick}
          wolfBooking={this.props.wolfBooking}
        />
      </BookingPage>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { match } = ownProps;
  const { date, eventSlug, lng } = match.params;
  return {
    performances: performanceSelectors.getPerformancesWithDatesForDay(
      state,
      date
    ),
    performancesLoading: performanceSelectors.getLoading(state),
    eventSlug,
    lng
  };
};

export default connect(mapStateToProps, {
  ...performanceActions
})(SelectTimeContainer);
