import React from 'react';
import Button from '../../components/Button/Button';
import DWTFFooter from '../../components/DWTFFooter/DWTFFooter';
import MainContent, {
  ContentContainer,
  PageContentBlock,
  TextBlock
} from '../../components/MainContent/MainContent';
import DWTFLogoLarge from '../../images/doctor-who/DoctorWho_logo_large.png';
import VisitingUsAccordion from '../../components/Accordion/VisitingUsAccordion';
import FAQAccordionHeader from '../../components/Accordion/FAQAccordionHeader';
import CovidAccordionHeader from '../../components/Accordion/CovidAccordionHeader';
import ImmersiveAccordionHeader from '../../components/Accordion/ImmersiveAccordionHeader';
import AccessibilityAccordionHeader from '../../components/Accordion/AccessibilityAccordionHeader';
import BookingAccordionHeader from '../../components/Accordion/BookingAccordionHeader';
import GallifreyanAccordionHeader from '../../components/Accordion/GallifreyanAccordionHeader';
import useScrollToHash from '../../utils/useScrollToHash';

const DoctorWhoFaq = ({ match }) => {
  useScrollToHash();

  return (
    <div className='App App--gatsby App--gatsby-terms'>
      <MainContent gatsby>
        <ContentContainer shadow='true' gatsby='true'>
          <PageContentBlock>
            <a className='terms-logo' href='/'>
              <img
                src={DWTFLogoLarge}
                alt='Doctor Who: Time Fracture'
                className='full-image-background-cover__content-image'
              />
            </a>

            <h2 className='gatsby-h2'>Your Visit</h2>

            <TextBlock full toppadding>
              <VisitingUsAccordion />
            </TextBlock>
          </PageContentBlock>

          <PageContentBlock nomargin>
            <h2 className='gatsby-h2'>Additional Information</h2>
          </PageContentBlock>

          <TextBlock full toppadding>
            <FAQAccordionHeader />
          </TextBlock>

          <TextBlock full toppadding>
            <CovidAccordionHeader />
          </TextBlock>

          <TextBlock full toppadding>
            <ImmersiveAccordionHeader />
          </TextBlock>

          <TextBlock full toppadding>
            <AccessibilityAccordionHeader />
          </TextBlock>

          <TextBlock full toppadding>
            <BookingAccordionHeader />
          </TextBlock>

          <TextBlock full toppadding>
            <GallifreyanAccordionHeader />
          </TextBlock>

          <PageContentBlock>
            <TextBlock toppadding>
              <div className='large-cta terms-cta'>
                <a href='/'>
                  <Button className='button--gatsby button--large button--mobile-full'>
                    Back to Booking
                  </Button>
                </a>
              </div>
            </TextBlock>
            <TextBlock toppadding>
              <p className='credits'>
                BBC, DOCTOR WHO, TARDIS, DALEKS and CYBERMAN (word marks and
                logos) are trade marks of the British Broadcasting Corporation
                and are used under licence. BBC logo © BBC 1996. Doctor Who logo
                © BBC 2018. Dalek image © BBC/Terry Nation 1963. Cyberman image
                © BBC/Kit Pedler/Gerry Davis 1966. Licensed by BBC Studios.
              </p>
            </TextBlock>
          </PageContentBlock>
        </ContentContainer>
      </MainContent>

      <DWTFFooter />
    </div>
  );
};

export default DoctorWhoFaq;
