import React from 'react';
import './BasketSummary.css';
import LineItem from './LineItem/LineItem';
import DiscountCode from './DiscountCode/DiscountCode';
/* eslint-disable react/style-prop-object */
import { FormattedNumber } from 'react-intl';
import { getDateFromDateTime } from '../../../utils/dateUtils';
import Button from '../../Button/Button';
import classNames from 'classnames';

const BasketSummary = ({
  ticketItems,
  productItems,
  basketTotal,
  faceValueTotal,
  bookingFeeTotal,
  totalDiscount,
  currency,
  currentDiscountCode,
  onEditBasketClick,
  onHideDiscountForm,
  onShowDiscountForm,
  onDiscountSubmit,
  shouldShowDiscountForm,
  className,
  wolfBooking
}) => {
  const ticketLineItems = Object.keys(ticketItems).map(id => ticketItems[id]);
  const productLineItems = Object.keys(productItems).map(
    id => productItems[id]
  );

  const handleEditBasketClick = ev => {
    ev.preventDefault();
    onEditBasketClick && onEditBasketClick();
  };
  const getTicketRow = ticket => (
    <LineItem
      description={ticket.description}
      faceValue={ticket.totalExFees}
      bookingFee={ticket.feeTotal}
      currency={ticket.currency}
      quantity={ticket.quantity}
      key={ticket.id}
    />
  );
  const getProductRow = product => (
    <LineItem
      description={product.title}
      faceValue={product.total}
      currency={product.currency}
      quantity={product.quantity}
      key={product.id}
    />
  );
  const getPerformanceRow = performance => (
    <div key={performance.id} className='basket-summary__section-details'>
      <span className='basket-summary__event-date'>
        {getDateFromDateTime(
          performance.startDate,
          performance.startTime
        ).format('dddd, MMMM Do YYYY, h:mm a')}
      </span>
      {performance.tickets.map(ticket => getTicketRow(ticket))}
    </div>
  );
  const getEventRow = event => {
    const performanceItems = Object.keys(event.performances).map(
      id => event.performances[id]
    );
    return (
      <div key={event.id} className='basket-summary__section'>
        <span className='basket-summary__section-title'>{event.title}</span>
        {performanceItems.map(performance => getPerformanceRow(performance))}
      </div>
    );
  };
  const basketSummaryClass = classNames(
    'basket-summary__main-content',
    className,
    {
      'basket-summary__main-content--wolf': wolfBooking
    }
  );
  const basketSummaryButtonClass = classNames(
    'basket-summary__edit-button',
    className,
    {
      'basket-summary__edit-button--wolf': wolfBooking
    }
  );
  return (
    <div className='basket-summary'>
      <div className='basket-summary__title'>Your Order</div>
      <div className={basketSummaryClass}>
        <div className='basket-summary__header'>
          <span className='basket-summary__sub-title'>Basket Summary</span>
          <Button
            onClick={handleEditBasketClick}
            className={basketSummaryButtonClass}
          >
            Edit Basket
          </Button>
        </div>
        {ticketLineItems.map(event => getEventRow(event))}
        {productLineItems.length > 0 && (
          <div className='basket-summary__section'>
            <span className='basket-summary__section-title'>
              Additional Products
            </span>
            <div
              key={performance.id}
              className='basket-summary__section-details'
            >
              {productLineItems.map(product => getProductRow(product))}
            </div>
          </div>
        )}

        <div className='basket-summary__section'>
          <span className='basket-summary__line-item'>
            <span>Subtotal</span>
            <span className='basket-summary__value'>
              <FormattedNumber
                value={faceValueTotal}
                style='currency'
                currency={currency}
              />
            </span>
          </span>
          {bookingFeeTotal > 0 && (
            <span className='basket-summary__line-item'>
              <span>Booking fees</span>
              <span className='basket-summary__value'>
                <FormattedNumber
                  value={bookingFeeTotal}
                  style='currency'
                  currency={currency}
                />
              </span>
            </span>
          )}

          {currentDiscountCode && (
            <span className='basket-summary__line-item'>
              <span>Discount</span>
              {totalDiscount > 0 ? (
                <span className='basket-summary__value'>
                  <FormattedNumber
                    value={totalDiscount * -1}
                    style='currency'
                    currency={currency}
                  />
                </span>
              ) : (
                <span className='basket-summary__discount-code'>
                  {currentDiscountCode.toUpperCase()}
                </span>
              )}
            </span>
          )}
          <span className='basket-summary__line-item'>
            <DiscountCode
              shouldShowDiscountForm={shouldShowDiscountForm}
              hideDiscountForm={onHideDiscountForm}
              showDiscountForm={onShowDiscountForm}
              onDiscountSubmit={onDiscountSubmit}
              wolfBooking={wolfBooking}
            />
          </span>
        </div>

        <div className='basket-summary__section'>
          <span className='basket-summary__line-item basket-summary__line-item--total'>
            <span>Total</span>
            <span className='basket-summary__value'>
              <FormattedNumber
                value={basketTotal}
                style='currency'
                currency={currency}
              />
            </span>
          </span>
        </div>
      </div>
      <span className='basket-summary__note'>
        Check your order carefully as tickets cannot be exchanged or refunded.
      </span>
    </div>
  );
};

export default BasketSummary;
