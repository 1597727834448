import { connect } from 'react-redux';
import { ProductRow } from '../../../components/Booking/Booking';
import { basketSelectors } from '../../../modules/basket/basket';

const mapStateToProps = (state, ownProps) => {
  const productId = ownProps.productId;
  return {
    quantitySelected: basketSelectors.getProductQuantity(state, productId)
  };
};

export default connect(mapStateToProps)(ProductRow);
