import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import './Accordion.css';

const VisitingUs = () => {
  return (
    <Accordion className='accordion' allowZeroExpanded>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              I'm looking for a specific date. When are you booking to?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>We're booking to Sunday 17th April 2022.</p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>What should I wear?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            You’re welcome to dress up as your favourite Doctor or Doctor Who
            character if you wish. Equally, you don’t have to. The decision is
            entirely yours, and you’ll have the same wonderful experience either
            way.{' '}
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              Can I take photographs during the performance?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Phones and cameras must be switched off throughout the performance,
            though there will be dedicated photo opportunities throughout the
            venue and you will always be able to use your phone for contactless
            payment at our bars and retail outlets.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>Where is the venue?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Doctor Who: Time Fracture is playing at Immersive | LDN. Our address
            is UNIT HQ, 1-8 Davies Mews, London W1K 5AB. The closest underground
            station is Bond Street.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>Will you have a gift shop?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            We will indeed! This will be available inside the venue and will
            feature a range of Official <em>Doctor Who</em> and{' '}
            <em>Doctor Who: Time Fracture</em> merchandise.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>What are the performance times?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            The standard performances schedule is Wednesday at 12pm, 12.45pm,
            6pm and 6.45pm. Thursday at 6pm and 6.45pm, Friday at 6:00pm, 6:45pm
            and 7:30pm, Saturday at 12:00pm, 12:45pm, 1:30pm, 6:00pm, 6:45pm &
            7.30pm. Sunday at 12pm, 12.45pm and 1.30pm. In certain busy weeks
            extra performances are added to meet increased demand for tickets so
            please check the booking calendar for more information.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>Do I need to print my ticket?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Printed tickets aren’t required, your name(s) will be at the door on
            arrival.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              Will I be able to buy drinks and food at the venue?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Yes, customers will be able to purchase drinks at bars throughout
            the show.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>Do you allow latecomers?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Yes, we are able to hold the doors open for 15 minutes after each
            given time slot. If this is missed and there is availablity in the
            time slot after your original booking then our Front of House team
            will do their best to fit you in. If you are in the final time slot,
            and miss the 15 minute window unfortunately we cannot accomodate
            your booking.{' '}
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              Can I leave and re-enter the production?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Due to the nature of immersive theatre, if you leave mid-way through
            the production you will not be able to re-enter.{' '}
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      {/* <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>Is the venue family friendly? </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>Answer</p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>Does the venue have a bar?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>Answer</p>
        </AccordionItemPanel>
      </AccordionItem> */}

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>What time should I arrive?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Please aim to arrive 15 minutes before your designated time slot.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              Can I bring my own food and drink to the venue?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            No, please note that no food or liquid can be brought into the
            venue. Food and drink can be stored as an item in the cloakroom if
            necessary. All water bottles etc will be emptied by security. There
            are water coolers available to fill water bottles around the venue.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              Can anyone aged 16 or under attend unaccompanied?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>Anyone aged 16 or under must also be accompanied by an adult.</p>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default VisitingUs;
