import React from 'react';

const Weekday = props => {
  const {
    weekday,
    className,
    weekdaysLong,
    weekdaysShort,
    localeUtils,
    locale
  } = props;
  let { title, shortTitle } = props;

  if (weekdaysLong) {
    title = weekdaysLong[weekday];
    shortTitle = weekdaysShort[weekday];
  } else {
    title = localeUtils.formatWeekdayLong(weekday, locale);
    shortTitle = localeUtils.formatWeekdayShort(weekday, locale);
  }

  return (
    <div className={className} role="columnheader">
      <span className="weekday-title" title={title}>
        {title}
      </span>
      <span className="weekday-title--mobile" title={title}>
        {shortTitle}
      </span>
    </div>
  );
};

export default Weekday;
