import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import './Accordion.css';

const GallifreyanAccordion = () => {
  return (
    <Accordion className='accordion' allowZeroExpanded>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              I need to book an Essential Companion ticket when redeeming my
              token, what should I do?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Please redeem your 'Gallifreyan Coin' presale Token as instructed,
            then contact{' '}
            <a href='mailto:ticketing@artstickets.co.uk' className='faq_link'>
              ArtsTickets
            </a>{' '}
            quoting your booking reference number to arrange your Essential
            Companion ticket.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>How do I redeem my Token?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            Select your performance of choice and the number of tickets
            required. If you wish to upgrade your ticket to include an Early
            Bird UNIT merchandise bundle, please do this now.
          </p>

          <p>
            Either log in to your account using the details from your original
            booking or enter new account details.
          </p>

          <p>
            Click Add Discount/Voucher Code at the foot of the ticket summary
            and enter the booking reference number from your original token
            booking confirmation. Click Apply and the discount will be applied
            to your basket total.
          </p>

          <p>
            If you have a remaining balance, please enter your card details. If
            your basket total is zero, you do not need to enter your card
            details. Click Book Now to confirm your tickets.
          </p>

          <p>You'll shortly receive an email with details of your booking.</p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>
              When will I receive my exclusive Gallifreyan Coin Pin Badge?
            </h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            If you purchased a 'Gallifreyan Coin' presale Token, you’ll receive
            your Pin Badge on arrival at the venue for your booked performance.
          </p>
        </AccordionItemPanel>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <h3 className='gatsby-h3'>Where can I use my £10 credit?</h3>
            <div className='accordion__arrow' />
          </AccordionItemButton>
        </AccordionItemHeading>
        <AccordionItemPanel>
          <p>
            ‘Gallifreyan Coin’ presale Token holders receive a £10 credit to be
            used throughout the venue. If you would rather not benefit from this
            you can request the difference in price between your ticket and your
            ‘Gallifreyan Coin’ presale Token is refunded. This difference will
            in all cases be less than £10. Please note that refunds are minus
            the original booking fee as this is non-refundable. To make your
            request, please contact{' '}
            <a href='mailto:ticketing@artstickets.co.uk' className='faq_link'>
              ArtsTickets
            </a>
            .
          </p>
        </AccordionItemPanel>
      </AccordionItem>
    </Accordion>
  );
};

export default GallifreyanAccordion;
