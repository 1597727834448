import React from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel
} from 'react-accessible-accordion';
import GallifreyanAccordion from './GallifreyanAccordion';
import './Accordion.css';
import { getAccordionPreExpanded } from './getAccordionPreExpanded';

const GallifreyanAccordionHeader = () => {
  const preExpanded = getAccordionPreExpanded('gallifreyan');
  return (
    <div id='gallifreyan'>
      <Accordion
        className='accordion'
        allowZeroExpanded
        preExpanded={preExpanded}
      >
        <AccordionItem uuid='gallifreyan'>
          <AccordionItemHeading>
            <AccordionItemButton>
              <h3 className='gatsby-h3'>Gallifreyan Token</h3>
              <div className='accordion__arrow' />
            </AccordionItemButton>
          </AccordionItemHeading>
          <AccordionItemPanel>
            <GallifreyanAccordion />
          </AccordionItemPanel>
        </AccordionItem>
      </Accordion>
    </div>
  );
};

export default GallifreyanAccordionHeader;
